import { createContext, useContext } from "react";
import TicketStore from "./ticketStore";
import CommonStore from "./commonStore";
import DataSourceStore from "./dataSourceStore";
import JobLaborStore from "./jobLaborStore";
import ModalStore from "./modalStore";
import ProfileStore from "./profileStore";
import UserStore from "./userStore";
import VehicleStore from "./vehicleStore";
import SettingsStore from "./settingsStore";
import VendorStore from "./vendorStore";
import TechnicianStore from "./technicianStore";
import CustomVehicleStore from "./customVehicleStore";
import ClientStore from "./clientStore";
import MakeListStore from "./makeListStore";
import DisclaimerStore from "./disclaimersStore";
import SubscriptionStore from "./subscriptionStore";
import QuickVinStore from "./quickVinStore";
import PricingStore from "./pricingStore";
import PaymentProcessingStore from "./paymentProcessingStore";
import PackageJobStore from "./packageJobsStore";
import MiscellaneousChargeStore from "./miscellaneousCharges";
import CustomerStore from "./customerStore";
import TechnicianReportStore from "./reportStores/technicianReportStore";
import VendorReportStore from "./reportStores/vendorReportStore";
import CustomerVehicleStore from "./customerVehicleStore";
import SearchStore from "./searchStore";
import TicketSessionStore from "./ticketSessionStore";
import SystemAdminStore from "./systemAdminStore";
import PartStore from "./partStore";
import TotalSalesReportStores from "./reportStores/totalSalesReportStore";
import LaborSalesReportStore from "./reportStores/laborSalesReportStore";
import PartSalesReportStore from "./reportStores/partSalesReportStore";
import MiscellaneousChargeReportStore from "./reportStores/miscellaneousChargeReportStore";
import TaxReportStore from "./reportStores/taxReportStore";
import PayTypeReportStore from "./reportStores/paytypeReportStore";
import CreditCardTransactionReportStore from "./reportStores/cardTransactionReportStore";
import CustomerHistoryReportStore from "./reportStores/customerHistoryReportStore";
import AdminNotesStore from "./systemAdminStores/adminNotesStore";
import AuthenticationLogStore from "./systemAdminStores/authenticationLogStore";
import ClientSystemAdminStore from "./systemAdminStores/clientSystemAdminStore";
import UserSystemAdminStore from "./systemAdminStores/userSystemAdminStore";
import ClientReviewStore from "./systemAdminStores/clientReviewStore";
import GeoLocationStore from "./systemAdminStores/geoLocationStore";
import StatsStore from "./systemAdminStores/reports/statsOverviewStore";
import CompanyDataRecoveryStore from "./systemAdminStores/companyDataRecoveryStore";
import TicketDataRecoveryStore from "./systemAdminStores/ticketDataRecoveryStore";
import TicketSessionDataRecoveryStore from "./systemAdminStores/ticketSessionDataRecoveryStore";
import VehicleDataRecoveryStore from "./systemAdminStores/vehicleDataRecoveryStore";
import ClientExtensionStore from "./systemAdminStores/clientExtensionStore";
import SystemAdminReportStore from "./systemAdminStores/reports/systemAdminReportStore";
import AnnouncementStore from "./systemAdminStores/announcementStore";
import EmailLogsStore from "./systemAdminStores/emailLogsStore";
import TicketPaymentProcessingStore from "./ticketPaymentProcessingStore";
import RecurringChargesStore from "./systemAdminStores/reports/recurringChargesStore";
import CancellationReportStore from "./systemAdminStores/reports/cancellationReportStore";
import AuthorizeNetReportStore from "./systemAdminStores/reports/authorizeNetReportStore";
import NextChargeAdjustmentsStore from "./systemAdminStores/nextChargeAdjustmentsStore";
import EarlyChargeStore from "./systemAdminStores/earlyChargeStore";
import TrialActivityStore from "./systemAdminStores/trialActivityStore";
import SubscriberReportStore from "./systemAdminStores/reports/subscriberReport";
import QuickVinNightlyLogStore from "./systemAdminStores/quickVinNightlyStore";
import CustomerTicketStore from "./customerTicketStore";
import CompanyUsersStore from "./systemAdminStores/companyUsersStore";
import TrialRequestsStore from "./systemAdminStores/trialRequestsStore";
import CarFaxShopDataStore from "./systemAdminStores/carFaxShopDataStore";
import SelectableCommentsStore from "./selectableCommentsStore";

interface Store {
  ticketStore: TicketStore;
  systemAdminStore: SystemAdminStore;
  commonStore: CommonStore;
  userStore: UserStore;
  modalStore: ModalStore;
  profileStore: ProfileStore;
  vehicleStore: VehicleStore;
  jobLaborStore: JobLaborStore;
  dataSourceStore: DataSourceStore;
  settingsStore: SettingsStore;
  vendorStore: VendorStore;
  technicianStore: TechnicianStore;
  customVehicleStore: CustomVehicleStore;
  clientStore: ClientStore;
  makeListStore: MakeListStore;
  disclaimerStore: DisclaimerStore;
  subscriptionStore: SubscriptionStore;
  quickVinStore: QuickVinStore;
  pricingStore: PricingStore;
  paymentProcessingStore: PaymentProcessingStore;
  packageJobStore: PackageJobStore;
  miscellaneousChargeStore: MiscellaneousChargeStore;
  customerStore: CustomerStore;
  technicianReportStore: TechnicianReportStore;
  vendorReportStore: VendorReportStore;
  customerVehicleStore: CustomerVehicleStore;
  searchStore: SearchStore;
  ticketSessionStore: TicketSessionStore;
  partsStore: PartStore;
  totalSalesReportStore: TotalSalesReportStores;
  laborSalesReportStore: LaborSalesReportStore;
  partSalesReportStore: PartSalesReportStore;
  miscChargesReportStore: MiscellaneousChargeReportStore;
  taxReportStore: TaxReportStore;
  payTypeReportStore: PayTypeReportStore;
  creditCardTransactionReportStore: CreditCardTransactionReportStore;
  customerHistoryReportStore: CustomerHistoryReportStore;
  adminNotesStore: AdminNotesStore;
  authenticationLogStore: AuthenticationLogStore;
  clientSystemAdminStore: ClientSystemAdminStore;
  userSystemAdminStore: UserSystemAdminStore;
  clientReviewStore: ClientReviewStore;
  geoLocationStore: GeoLocationStore;
  statsStore: StatsStore;
  customerDataRecoveryStore: CompanyDataRecoveryStore;
  ticketDataRecoveryStore: TicketDataRecoveryStore;
  ticketSessionDataRecoveryStore: TicketSessionDataRecoveryStore;
  vehicleDataRecoveryStore: VehicleDataRecoveryStore;
  clientExtensionStore: ClientExtensionStore;
  systemAdminReportStore: SystemAdminReportStore;
  announcementStore: AnnouncementStore;
  emailLogsStore: EmailLogsStore;
  ticketPaymentProcessingStore: TicketPaymentProcessingStore;
  recurringChargesStore: RecurringChargesStore;
  cancellationReportStore: CancellationReportStore;
  nextChargeAdjustmentsStore: NextChargeAdjustmentsStore;
  earlyChargeStore: EarlyChargeStore;
  trialActivityStore: TrialActivityStore;
  subscriberReportStore: SubscriberReportStore;
  quickVinNightlyLogStore: QuickVinNightlyLogStore;
  customerTicketStore :CustomerTicketStore;
  companyUsersStore: CompanyUsersStore;
  authorizeNetReportStore: AuthorizeNetReportStore;
  trialRequestsStore: TrialRequestsStore;
  carfaxShopDataStore: CarFaxShopDataStore;
  selectableCommentsStore: SelectableCommentsStore;
}

export const store: Store = {
  ticketStore: new TicketStore(),
  systemAdminStore: new SystemAdminStore(),
  commonStore: new CommonStore(),
  userStore: new UserStore(),
  modalStore: new ModalStore(),
  profileStore: new ProfileStore(),
  vehicleStore: new VehicleStore(),
  jobLaborStore: new JobLaborStore(),
  dataSourceStore: new DataSourceStore(),
  settingsStore: new SettingsStore(),
  vendorStore: new VendorStore(),
  technicianStore: new TechnicianStore(),
  customVehicleStore: new CustomVehicleStore(),
  clientStore: new ClientStore(),
  makeListStore: new MakeListStore(),
  disclaimerStore: new DisclaimerStore(),
  subscriptionStore: new SubscriptionStore(),
  quickVinStore: new QuickVinStore(),
  pricingStore: new PricingStore(),
  paymentProcessingStore: new PaymentProcessingStore(),
  packageJobStore: new PackageJobStore(),
  miscellaneousChargeStore: new MiscellaneousChargeStore(),
  customerStore: new CustomerStore(),
  technicianReportStore: new TechnicianReportStore(),
  vendorReportStore: new VendorReportStore(),
  customerVehicleStore: new CustomerVehicleStore(),
  searchStore: new SearchStore(),
  ticketSessionStore: new TicketSessionStore(),
  partsStore: new PartStore(),
  totalSalesReportStore: new TotalSalesReportStores(),
  laborSalesReportStore: new LaborSalesReportStore(),
  partSalesReportStore: new PartSalesReportStore(),
  miscChargesReportStore: new MiscellaneousChargeReportStore(),
  taxReportStore: new TaxReportStore(),
  payTypeReportStore: new PayTypeReportStore(),
  creditCardTransactionReportStore: new CreditCardTransactionReportStore(),
  customerHistoryReportStore: new CustomerHistoryReportStore(),
  adminNotesStore: new AdminNotesStore(),
  authenticationLogStore: new AuthenticationLogStore(),
  clientSystemAdminStore: new ClientSystemAdminStore(),
  userSystemAdminStore: new UserSystemAdminStore(),
  clientReviewStore: new ClientReviewStore(),
  geoLocationStore: new GeoLocationStore(),
  statsStore: new StatsStore(),
  customerDataRecoveryStore: new CompanyDataRecoveryStore(),
  ticketDataRecoveryStore: new TicketDataRecoveryStore(),
  ticketSessionDataRecoveryStore: new TicketSessionDataRecoveryStore(),
  vehicleDataRecoveryStore: new VehicleDataRecoveryStore(),
  clientExtensionStore: new ClientExtensionStore(),
  systemAdminReportStore: new SystemAdminReportStore(),
  announcementStore: new AnnouncementStore(),
  emailLogsStore: new EmailLogsStore(),
  ticketPaymentProcessingStore: new TicketPaymentProcessingStore(),
  recurringChargesStore: new RecurringChargesStore(),
  cancellationReportStore: new CancellationReportStore(),
  nextChargeAdjustmentsStore: new NextChargeAdjustmentsStore(),
  earlyChargeStore: new EarlyChargeStore(),
  trialActivityStore: new TrialActivityStore(),
  subscriberReportStore: new SubscriberReportStore(),
  quickVinNightlyLogStore: new QuickVinNightlyLogStore(),
  customerTicketStore: new CustomerTicketStore(),
  companyUsersStore: new CompanyUsersStore(),
  authorizeNetReportStore: new AuthorizeNetReportStore(),
  trialRequestsStore: new TrialRequestsStore(),
  carfaxShopDataStore: new CarFaxShopDataStore(),
  selectableCommentsStore: new SelectableCommentsStore(),
};

export const StoreContext = createContext(store);

export function useStore() {
  return useContext(StoreContext);
}
