import { observer } from "mobx-react-lite";
import { Button, Message, Table } from "semantic-ui-react";
import { useTranslation } from "react-i18next";
import { useStore } from "../../../app/stores/store";
import { useEffect } from "react";
import { PagingParams } from "../../../app/models/pagination";
import { format } from "date-fns";
import MyPaging from "../../../app/common/form/MyPaging";

interface Props {
  clientId: string;
}
export default observer(function DeletedVehicle({ clientId }: Props) {
  const { t } = useTranslation(["common", "companySettings"]);
  const { vehicleDataRecoveryStore } = useStore();

  const {
    getDeletedVehicles,
    loadDeletedVehicles,
    restoreVehicle,
    pagination,
    DeletedVehiclesRegistry,
    setPagingParams,
    pagingParams,
    setPredicate
  } = vehicleDataRecoveryStore;

  useEffect(() => {
    setPredicate("clientId", clientId);
    loadDeletedVehicles(clientId ?? "");
  }, [loadDeletedVehicles, getDeletedVehicles.length,  clientId]);

  function listOnClick(value: number) {
    if (value > 0 && pagination && value < pagination?.totalPages + 1) {
      setPagingParams(new PagingParams(value));
      DeletedVehiclesRegistry.clear();
      loadDeletedVehicles(clientId);
    }
  }

  return (
    <Table celled>
      <Table.Header>
        <Table.Row>
          <Table.HeaderCell>Vehicle Id</Table.HeaderCell>
          <Table.HeaderCell>Customer Name</Table.HeaderCell>
          <Table.HeaderCell>Vehicle Description</Table.HeaderCell>
          <Table.HeaderCell>Ticket Count</Table.HeaderCell>
          <Table.HeaderCell>Date Deleted</Table.HeaderCell>
          <Table.HeaderCell>Deleted By</Table.HeaderCell>
          <Table.HeaderCell>Restore</Table.HeaderCell>
        </Table.Row>
      </Table.Header>
      <Table.Body>
        {getDeletedVehicles && getDeletedVehicles.length > 0 ? (
          getDeletedVehicles.map((x) => (
            <Table.Row key={`vehicle.${x.vehicleId}`}>
              <Table.Cell>{x.vehicleId}</Table.Cell>
              <Table.Cell>{x.customerName}</Table.Cell>
              <Table.Cell>{x.vehicleName}</Table.Cell>
              <Table.Cell>{x.tickets}</Table.Cell>
              <Table.Cell>{format(x.deletedDate, "P p")}</Table.Cell>
              <Table.Cell>{x.deletedBy}</Table.Cell>
              <Table.Cell>
                {" "}
                <Button
                  className="save_button"
                  icon="check"
                  content="Restore"
                  type="submit"
                  onClick={() =>
                    restoreVehicle(x.vehicleId)
                  }
                />
              </Table.Cell>
            </Table.Row>
          ))
        ) : (
          <Table.Row key={"noItems"}>
            <Table.Cell width="7" colSpan={7}>
              <Message
                color="yellow"
                className="textAlignCenter fontStyleItalic"
              >
                {t("grid_noItems", { ns: "common" })}
              </Message>
            </Table.Cell>
          </Table.Row>
        )}
      </Table.Body>
      <Table.Footer>
        <Table.Row>
          <Table.HeaderCell colSpan="7">
            <MyPaging
              itemCount={pagination ? pagination.totalItems : 25}
              pageSize={pagingParams.pageSize}
              currentPage={pagination ? pagination.currentPage : 1}
              onClick={listOnClick}
              onChange={listOnClick}
              floated="right"
            ></MyPaging>
          </Table.HeaderCell>
        </Table.Row>
      </Table.Footer>
    </Table>
  );
});
