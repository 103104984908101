import { observer } from "mobx-react-lite";
import React, { useEffect, useState } from "react";
import { Dropdown, Grid, Loader, Table } from "semantic-ui-react";
import { useTranslation } from "react-i18next";

import PageHeader from "../../../app/layout/PageHeader";
import LoadingComponent from "../../../app/layout/LoadingComponent";
import { useStore } from "../../../app/stores/store";
import RecentTicketsItem from "./RecentTicketsItem";
import MyPaging from "../../../app/common/form/MyPaging";
import { PagingParams } from "../../../app/models/pagination";
import { recentTicketsSortOptions, sortOrderOptions } from "../../../app/common/options/option";
import { RecentTicketSortKeys, OrderDirection } from "../../../app/models/ticket";
import { Application } from "../../../app/models/enums";

export default observer(function RecentTickets() {
  const { t } = useTranslation(["common", "recentTickets"]);
  const [sortKey, setSortKey] = useState<RecentTicketSortKeys | undefined>();
  const [order, setOrder] = useState<OrderDirection | undefined>();
  const { ticketStore, userStore } = useStore();
  const { checkApplication } = userStore;
  const {
    loadingInitial,
    pagingParams,
    setPagingParams,
    pagination,
    loadTickets,
    ticketsByDate,
    resetRecentTickets,
    setRecentTicketsSortPredicate,
    recentTicketsSorting,
  } = ticketStore;

  //TODO fix infinite scroller
  // const [loadingNext, setLoadingNext] = useState(false);

  // function handleGetNext() {
  //   setLoadingNext(true);
  //   setPagingParams(new PagingParams(pagination!.currentPage + 1));
  //   loadTickets().then(() => setLoadingNext(false));
  // }

  function listOnClick(value: number) {
    if (value > 0 && pagination && value < pagination?.totalPages + 1) {
      setPagingParams(new PagingParams(value, 25));
      resetRecentTickets();
      loadTickets();
    }
  }

  useEffect(() => {
    setPagingParams(new PagingParams(1, 25));
    resetRecentTickets();
    loadTickets();
  }, [loadTickets, resetRecentTickets,setPagingParams ]);

  if (loadingInitial)
    return <LoadingComponent content={t("loading", { ns: "common" })} />;

  return (
    <>
      <PageHeader
        header={t("title", { ns: "recentTickets" })}
        type={"h1"}
        divider={true}
        addTitle={true}
      />
      {checkApplication(Application.AutomotiveExpert) && (
        <Grid stackable>
          <Grid.Row columns={3}>
            <Grid.Column>
              <div className="d-flex">

                <label className="myLabel col-4 col-md-3 py-2">{t("sortBy", { ns: "common" })}:</label>
                <Dropdown
                  float="right"
                  placeholder={recentTicketsSortOptions[0].text}
                  name={"sortKey"}
                  fluid
                  selection
                  clearable
                  options={recentTicketsSortOptions}
                  value={sortKey}
                  onChange={(e, d) => {
                    setSortKey(d.value ? d.value as RecentTicketSortKeys : undefined);
                    setRecentTicketsSortPredicate('sortKey', d.value?.toString())
                  }}
                />
              </div>
            </Grid.Column>
            <Grid.Column>              
              <div className="d-flex">
                <label className="myLabel col-4 col-md-3 py-2">{t("sortOrder", { ns: "common" })}:</label>
                <Dropdown
                  placeholder={sortOrderOptions[0].text}
                  name={"order"}
                  fluid
                  selection
                  clearable
                  options={sortOrderOptions}
                  value={order}
                  onChange={(e, d) => {
                    setOrder(d.value ? d.value as OrderDirection : undefined);
                    setRecentTicketsSortPredicate('order', d.value?.toString())
                  }}
                />
            </div>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      )}
      {!recentTicketsSorting ? (
        <Table celled className="recentsMobile">
          <Table.Header className="recentSticky">
            <Table.Row>
              <Table.HeaderCell width={5} textAlign="center">
                {t("customer", { ns: "common" })}
              </Table.HeaderCell>
              <Table.HeaderCell width={5} textAlign="center">
                {t("vehicle", { ns: "common" })}
              </Table.HeaderCell>
              <Table.HeaderCell width={5} textAlign="center">
                {t("ticket", { ns: "common" })}
              </Table.HeaderCell>
            </Table.Row>
          </Table.Header>

          <Table.Body>
            {ticketsByDate &&
              ticketsByDate.length > 0 &&
              ticketsByDate.map((ticket, index) => (
                <Table.Row key={`recentTicketItem.${ticket.ticketId}`}>
                  <Table.Cell colSpan={3}>
                    <RecentTicketsItem
                      ticket={ticket}
                      initialOpen={index === 0}
                      key={ticket.ticketId}
                    />
                  </Table.Cell>
                </Table.Row>
              ))}
          </Table.Body>
          <Table.Footer>
          <Table.Row>
            <Table.Cell colSpan="6">
              <MyPaging
                itemCount={pagination ? pagination.totalItems : 10}
                pageSize={pagingParams.pageSize}
                currentPage={pagination ? pagination.currentPage : 1}
                onClick={listOnClick}
                onChange={listOnClick}
                floated="right"
              />
            </Table.Cell>
          </Table.Row>
        </Table.Footer>
        </Table>
    ) : (
      <Loader className="smallLoadingComponent" content={t("loading", { ns: "common" })} />
    )}
    </>
  );
});
