import CancellationReport from "../../features/systemAdmin/adminTools/CancellationReport";
import { ClientDetails } from "./client";
import {
  AccessLevel,
  PhoneNumberType,
  SystemAdminNoteType,
  TicketType,
  EmailType,
  Application,
  ClientStatus,
  TransactionType,
  PaymentStatus,
  SubscriptionType,
} from "./enums";

export interface CompanyApprovalReviewDTO {
  companyName: string;
  createdDate: Date;
  name: string;
  email: string;
  addressLine1: string;
  addressLine2: string;
  city: string;
  state: string;
  postalCode: string;
  primaryPhone: string;
  adminNotes: CompanyApprovalReviewNotes[];
  settingsUpdates: string[];
  clientId: string;
}

export interface CompanyApprovalReviewNotes {
  note: string;
  type: SystemAdminNoteType;
}

export interface ClientAdditionalInformation {
  id: string;
  createdDate: Date;
  status: string;
  laborDataAccess: string;
  quickVinStatus: string;
}

export interface SignUpReportView {
  date: Date;
  userCount: number;
  companyCount: number;
  status: string;
  accessLevel: AccessLevel;
}

export interface ClientTicketInformation {
  invoiceCount: number;
  numbers: string;
}

export interface ClientBillingSubscriptionInformation {
  id: string;
  date: Date;
  amount?: number;
  description: string;
  status: string;
  name: string;
  cardholderName: string;
  lastInt?: string;
}

export interface ClientSubscriptionHistory {
  id: string;
  plan: string;
  amount: number;
  startDate: Date;
  nextCharge?: Date;
  endDate: Date;
  cancelDate?: Date;
  lastFour: string;
  cardholderName: string;
  status: boolean;
}

export interface ClientContacts {
  emailAddress: string;
  firstName: string;
  lastName: string;
  phone: Phone[];
}

export interface Phone {
  number: string;
  type: PhoneNumberType;
}

export interface ClientUsers {
  clientId: string;
  userId: string;
  clientName: string;
  username: string;
  emailAddress: string;
  lastName: string;
  firstName: string;
  isActive: boolean;
  createdDate: Date;
}

export interface AuthenticationLogs {
  id: number;
  createdDate: Date;
  username: string;
  ipAddress: string;
  success: boolean;
  statusReason: string;
  admin: boolean;
  clientName: string;
  clientId: string;
  userId: string;
}

export interface ClientCompanyStatuses {
  statusType: string;
  createdDate: Date;
  endDate: Date;
  isActive: boolean;
}

export interface ClientAvailableTrials {
  trialType: string;
  endDate: Date;
}

export interface ClientList {
  createdDate: Date;
  id: string;
  companyName: string;
  addressLine1: string;
  addressLine2: string;
  city: string;
  state: string;
  postalCode: string;
  country: string;
  phone: string;
}

export interface UserDetails {
  id: string;
  clientId: string;
  createdDate: Date;
  username: string;
  email: string;
  companyName: string;
  title: string;
  firstName: string;
  lastName: string;
  phone: Phone[];
  accessLevel: AccessLevel;
  isActive: boolean;
  additionalAuthCheck: boolean;
  authCountryCheck: boolean;
  emailVerified: boolean;
  passwordSet: boolean;
}

export interface AdminNotes {
  id: string;
  clientId: string;
  userId?: string;
  clientName: string;
  note: string;
  createdBy: string;
  affectedName: string;
  type: string;
  createdDate: Date;
  AdminNoteType?: string;
  authorUserId?: string;
}

export class SystemAdminClientDetails extends ClientDetails {
  customerGatewayId?: string;
  paymentGatewayId?: string;
  invoiceCount?: number;
  laborDataAccess: boolean = false;
  dowellSystemApplication: Application = Application.None;
  status?: ClientStatus;
  createdDate?: Date;
  invoiceNumber?: number;
  estimateNumber?: number;
  poNumber?: number;
  trialEndDate?: Date;
  subscriptionEndDate?: Date;
  isSubscriptionExtension?: boolean;
  userCount?: number;
  subscriptionAmount?: number;
  nextChargeDate?: Date;
  quickVinEnabled?: boolean;
  quickVinApproved?: boolean;
  activeUserCount?: number;

  constructor(init?: SystemAdminClientDetails) {
    super(init);
    if (init) {
      this.invoiceCount = init.invoiceCount;
      this.laborDataAccess =
        init.laborDataAccess.toString() === "true" ? true : false;
      this.dowellSystemApplication = Number(init.dowellSystemApplication);
      this.status = init.status;
      this.createdDate = init.createdDate
        ? new Date(init.createdDate)
        : undefined;
      this.invoiceNumber = init.invoiceNumber;
      this.estimateNumber = init.estimateNumber;
      this.poNumber = init.poNumber;
      this.customerGatewayId = init.customerGatewayId;
      this.paymentGatewayId = init.paymentGatewayId;
      this.trialEndDate = init.trialEndDate
        ? new Date(init.trialEndDate)
        : undefined;
      this.subscriptionEndDate = init.subscriptionEndDate
        ? new Date(init.subscriptionEndDate)
        : undefined;
      this.isSubscriptionExtension = init.isSubscriptionExtension;
      this.userCount = init.userCount;
      this.subscriptionAmount = init.subscriptionAmount;
      this.nextChargeDate = init.nextChargeDate
        ? new Date(init.nextChargeDate)
        : undefined;
      this.quickVinEnabled = init.quickVinEnabled;
      this.quickVinApproved = init.quickVinApproved;
      this.activeUserCount = init.activeUserCount;
    }
  }
}

export interface TrialDetails {
  clientId?: string;
  trialStart?: Date;
  trialEnd?: Date;
  totalTrials?: number;
  latestIsTrial: boolean;
  isActive: boolean;
}

export interface StatsCompanyStatus {
  application: Application;
  status: ClientStatus;
  statusCount: number;
}

export interface StatsCompanyTerms {
  application: Application;
  term: number;
  isActive: boolean;
  count: number;
}

export interface StatsOverview {
  id: string;
  carFaxPending: number;
  carFaxTotalAccount: number;
  carFaxPendingClient: number;
  carFaxPendingTickets: number;
  carFaxPendingTicketsItems: number;
  lastCronRun: Date;
  quotesTotal: number;
  multipleRecurring: number;
  quotesCustomTotal: number;
  finalizedInvoices: number;
  customVehicles: number;
  internalStaff: number;
  internalStaffActive: number;
  internalManager: number;
  internalManagerActive: number;
  emailConfirmed: number;
  clientPendingReview: number;
  clientDeclined: number;
  clientApprovedNoLogin: number;
  clientApprovedTotal: number;
  clientSubscribed: number;
  clientUsersSubscribed: number;
  clientBasicTotal: number;
  clientProTotal: number;
  userBasicTotal: number;
  userProTotal: number;
  totalExtendedTrial: number;
  noServiceDeactivated: number;
  noServiceTrial: number;
  noServiceTrialExtended: number;
  basicDeactivated: number;
  basicTrial: number;
  basicTrialExtended: number;
  basicActive: number;
  basicExtended: number;
  basicAfterTrial: number;
  proDeactivated: number;
  proTrial: number;
  proTrialExtended: number;
  proActive: number;
  proExtended: number;
  proAfterTrial: number;
  companyStatuses: StatsCompanyStatus;
  companyTerms: StatsCompanyTerms;
}

export interface DeletedCustomers {
  customerId: string;
  firstName: string;
  lastName: string;
  company: string;
  tickets: number;
  deletedDate: Date;
  deletedBy: string;
}

export interface DeletedTickets {
  id: string;
  date: Date;
  type: TicketType;
  ticketNumber: string;
  poNumber: number;
  firstName: string;
  lastName: string;
  year: number;
  make: string;
  model: string;
  total: number;
  deletedBy: string;
  deletedDate: Date;
}

export enum DeletedTicketSessionSortKeys {
  DeletedDate = 0,
  CustomerName = 1,
  TicketNumber = 2,
  TicketType = 3
}

export interface DeletedTicketSessions {
  id: string;
  date: Date;
  clientId: string;
  type: TicketType;
  ticketNumber: string;
  poNumber: number;
  customerName: string;
  vehicleDescription: string;
  deletedBy: string;
  deletedDate: Date;
}

export interface DeletedVehicles {
  vehicleId: string;
  customerName: string;
  vehicleName: string;
  tickets: number;
  deletedDate: Date;
  deletedBy: string;
}

export interface SubscriptionGap {
  clientId: string;
  dateCreated: Date;
  gapStart: Date;
  gapEnd: Date | null;
  clientStatus: ClientStatus;
  gapLength: number | null;
}

export interface SubscriptionGapOverview {
  ended: number;
  active: number;
  extended: number;
}

interface ExtensionType {
  userTrialExtension: number;
  adminTrialExtension: number;
  userSubscriptionExtension: number;
  adminSubscriptionExtension: number;
}

export interface ExtensionReport {
  realTimeLaborGuideTrial: ExtensionType;
  realTimeLaborGuideActive: ExtensionType;
  realTimeLaborGuideActiveExtension: ExtensionType;
  realTimeProTrial: ExtensionType;
  realTimeProActive: ExtensionType;
  realTimeProActiveExtension: ExtensionType;
}

export interface TrialExtension {
  extendedLength: number;
}

export interface MultipleRecurring {
  clientId: string;
  clientName: string;
  recurringCount: number;
  subscriptionId: string[];
}

export interface GeoLocation {
  ip: string;
  hostname: string;
  type: string;
  continent_code: string;
  continent_name: string;
  country_code: string;
  country_name: string;
  region_code: string;
  region_name: string;
  city: string;
  zip: string;
  latitude: number;
  longitude: number;
  location: Location;
  time_zone?: TimeZone;
}

export interface Location {
  geoname_id: number;
  capital: string;
  languages: Language[];
  country_flag: string;
  country_flag_emoji: string;
  country_flag_emoji_unicode: string;
  calling_code: string;
  is_eu: boolean;
}

export interface TimeZone {
  id: string;
  current_time: Date;
  gmt_offset: number;
  code: string;
  is_daylight_saving: boolean;
}

export interface Language {
  code: string;
  name: string;
  native: string;
}

export interface EmailLog {
  clientId?: string;
  userId?: string;
  createdDate?: Date;
  email: string;
  emailType?: EmailType;
  success?: boolean;
}

export interface RecurringChargesReport {
  firstName?: string;
  lastName?: string;
  clientName?: string;
  id: string;
  amount: number;
}

export interface RecurringChargesTotal {
  total: number;
}

export interface CancellationReport {
  date: Date;
  count: number;
}

export interface NextChargeAdjustments {
  days: number;
  count: number;
}

export interface EarlyChargeReport {
  id: number;
  clientId: string;
  charge1: Date;
  charge2: Date;
  term: number;
  days: number;
  nextChargeDate: Date;
  nextChargeDays: number;
  newPlanTerm: number;
}

export interface TrialActivityReport {
  daysAgo: number;
  count: number;
}

export interface SubscriberReport {
  application: Application;
  term: number;
  userCount: number;
  subscriberCount: number;
}

export interface QuickVinNightlyLog {
  createdDate: Date;
  clientCount: number;
  clientRowCount: number;
}


// The count of users per client
export interface ClientUserTotalStat {
  clientId: string;
  userCount: number;
}

// The number of clients with a specific user count
export interface ClientUserStats {
  userCount: number;
  clientCount: number;
}

export interface ClientNoLoginReport {
  clientId: string;
  createdDate: Date;
}

export interface ActiveServiceType {
  initialApplication: Application;
  currentApplication: Application;
  status: ClientStatus;
  triedOtherTrials: boolean;
  count: number;
  logins: number;
}

export interface SignUpStatsOverview {
  emailConfirmed: number;
  clientPendingReview: number;
  clientDeclined: number;
  clientApprovedNoLogin: number;
  clientApprovedTotal: number;
  clientSubscribed: number;
  clientUsersSubscribed: number;
  reportDate: Date;
}


export interface CarFaxShopDataReport {
  dataDate: Date | undefined;
  processedDate?: Date | undefined;
  invoiceNumber: number;
  ticketLines: number;
}

export interface FreeTrialRequestEmail {
  id: number;
  createdDate: Date;
  contactName: string;
  companyName: string;
  email: string;
}

export interface FreeTrialRequestReport extends FreeTrialRequestEmail {
  addressLine1: string;
  city: string;
  state: string;
  postalCode: string;
  country: string;
  phoneNumber: string;
  isClean: boolean;
  isImported: boolean;
  isEmailed: boolean;
  isFollowUp: boolean;
  isRejected: boolean;
}

export interface FreeTrial {
  trialId: number[];
}

export interface SetTrial extends FreeTrial {
  setClean: boolean;
}

export interface AuthorizeNetReport {
  transactionId?: string;
  reportDate?: Date;
  status?: string;
  cardType?: string;
  amount: number;
  transactionType?: TransactionType;
  paymentStatus?: PaymentStatus;
  subscriptionType?: SubscriptionType;
  term?: number;
  application?: Application;
}
