import { Customer } from "./customer";
import {
  CreditCardType,
  PaymentStatus,
  PromisedDateOptions,
  RateTypes,
  SalesTaxOptionType,
  TicketItemType,
  TicketType,
  TicketWorkflowSteps,
  TimeType,
  TransactionType,
} from "./enums";
import { Vehicle } from "./vehicle";
import { JobLabor } from "./jobLabor";
import { Labor } from "./labor";

export interface SettingsTicketDefaults {
  id?: string;
  showLaborHours: boolean;
  showReturnOldParts: boolean;
  rateTypes: number;
  defaultPayTypeId?: string;
  showSeparateCharges: boolean;
  promisedDateOptions: number;
  estimateCharge: number;
  error?: string;
}

export enum RecentTicketSortKeys {
  TicketNumber = 0,
  PONumber = 1,
  TicketType = 2,
  Mileage = 3,
  TicketCount = 4,
  TotalAmount = 5,
  VehicleCount = 6,
  DateCustomerCreated = 7,
  TicketDate = 8,
}
export enum OrderDirection {
  Ascending = 0,
  Descending = 1,
}
export class SettingsTicketDefaults implements SettingsTicketDefaults {
  constructor(init?: SettingsTicketDefaultsFormValues) {
    if (init) {
      this.id = init.id;
      this.showLaborHours = init.showLaborHours.toString() === "true";
      this.showReturnOldParts = init.showReturnOldParts.toString() === "true";
      this.rateTypes = Number(init.rateTypes);
      if (init.defaultPayTypeId && init.defaultPayTypeId.length > 0)
        this.defaultPayTypeId = init.defaultPayTypeId;
      else init.defaultPayTypeId = undefined;
      this.showSeparateCharges = init.showSeparateCharges.toString() === "true";
      this.promisedDateOptions = Number(init.promisedDateOptions);
      this.estimateCharge = Number(init.estimateCharge);
      this.error = init.error;
    }
  }
}

export class SettingsTicketDefaultsFormValues {
  id?: string = undefined;
  showLaborHours: boolean = false;
  showReturnOldParts: boolean = false;
  rateTypes: RateTypes = RateTypes.Flat;
  defaultPayTypeId?: string = undefined;
  showSeparateCharges: boolean = false;
  promisedDateOptions: PromisedDateOptions = PromisedDateOptions.Today;
  estimateCharge: number = 0;
  error?: string;

  constructor(settingsTicketDefault?: SettingsTicketDefaultsFormValues) {
    if (settingsTicketDefault) {
      this.id = settingsTicketDefault.id;
      this.showLaborHours = settingsTicketDefault.showLaborHours;
      this.showReturnOldParts = settingsTicketDefault.showReturnOldParts;
      this.rateTypes = settingsTicketDefault.rateTypes;
      if (
        settingsTicketDefault.defaultPayTypeId &&
        settingsTicketDefault.defaultPayTypeId.length > 0
      )
        this.defaultPayTypeId = settingsTicketDefault.defaultPayTypeId;
      else settingsTicketDefault.defaultPayTypeId = undefined;
      this.showSeparateCharges = settingsTicketDefault.showSeparateCharges;
      this.promisedDateOptions = settingsTicketDefault.promisedDateOptions;
      this.estimateCharge = settingsTicketDefault.estimateCharge;
      this.error = settingsTicketDefault.error;
    }
  }
}

export interface SettingsTicketFormatting {
  id?: string;
  showBorderOnPrint: boolean;
  shadeAlternatingRows: boolean;
  customerSignatureLine: number;
  showNotesLabel: boolean;
  showPayment: boolean;
  showRemoveAndReplace: boolean;
  logoPlacement: number;
  showPONumber: boolean;
  showEstimateNumber: boolean;
  error?: string;
}

export class SettingsTicketFormatting implements SettingsTicketFormatting {
  constructor(init?: SettingsTicketFormattingFormValues) {
    if (init) {
      this.id = init.id;
      this.showBorderOnPrint = init.showBorderOnPrint.toString() === "true";
      this.shadeAlternatingRows =
        init.shadeAlternatingRows.toString() === "true";
      this.customerSignatureLine = Number(init.customerSignatureLine);
      this.showNotesLabel = init.showNotesLabel.toString() === "true";
      this.showPayment = init.showPayment.toString() === "true";
      this.showRemoveAndReplace =
        init.showRemoveAndReplace.toString() === "true";
      this.logoPlacement = Number(init.logoPlacement);
      this.showPONumber = init.showPONumber.toString() === "true";
      this.showEstimateNumber = init.showEstimateNumber.toString() === "true";
      this.error = init.error;
    }
  }
}

export class SettingsTicketFormattingFormValues {
  id?: string = undefined;
  showBorderOnPrint: boolean = false;
  shadeAlternatingRows: boolean = false;
  customerSignatureLine: number = 0;
  showNotesLabel: boolean = false;
  showPayment: boolean = false;
  showRemoveAndReplace: boolean = false;
  logoPlacement: number = 0;
  showPONumber: boolean = false;
  showEstimateNumber: boolean = false;
  error?: string = undefined;

  constructor(settingsTicketFormatting?: SettingsTicketFormattingFormValues) {
    if (settingsTicketFormatting) {
      this.id = settingsTicketFormatting.id;
      this.showBorderOnPrint = settingsTicketFormatting.showBorderOnPrint;
      this.shadeAlternatingRows = settingsTicketFormatting.shadeAlternatingRows;
      this.customerSignatureLine =
        settingsTicketFormatting.customerSignatureLine;
      this.showNotesLabel = settingsTicketFormatting.showNotesLabel;
      this.showPayment = settingsTicketFormatting.showPayment;
      this.showRemoveAndReplace = settingsTicketFormatting.showRemoveAndReplace;
      this.logoPlacement = settingsTicketFormatting.logoPlacement;
      this.showPONumber = settingsTicketFormatting.showPONumber;
      this.showEstimateNumber = settingsTicketFormatting.showEstimateNumber;
      this.error = settingsTicketFormatting.error;
    }
  }
}

export interface SettingsTicketNumber {
  id?: string;
  invoiceNumber: number;
  estimateNumber: number;
  autoPONumber: number;
  assignAutoPO: boolean;
  error?: string;
}

export class SettingsTicketNumber implements SettingsTicketNumber {
  constructor(init?: SettingsTicketNumberFormValues) {
    if (init) {
      this.id = init.id;
      this.invoiceNumber = Number(init.invoiceNumber);
      this.estimateNumber = Number(init.estimateNumber);
      this.autoPONumber = Number(init.autoPONumber);
      this.assignAutoPO = init.assignAutoPO.toString() === "true";
      this.error = init.error;
    }
  }
}

export class SettingsTicketNumberFormValues {
  id?: string = undefined;
  invoiceNumber: number = 0;
  estimateNumber: number = 0;
  autoPONumber: number = 0;
  assignAutoPO: boolean = false;
  error?: string = undefined;

  constructor(settingsTicketNumber?: SettingsTicketNumberFormValues) {
    if (settingsTicketNumber) {
      this.id = settingsTicketNumber.id;
      this.invoiceNumber = settingsTicketNumber.invoiceNumber;
      this.estimateNumber = settingsTicketNumber.estimateNumber;
      this.autoPONumber = settingsTicketNumber.autoPONumber;
      this.assignAutoPO = settingsTicketNumber.assignAutoPO;
      this.error = settingsTicketNumber.error;
    }
  }
}

export interface SettingsTicketInputDefaults {
  id?: string;
  capitalizeFirstCharacter: boolean;
  formatPhoneNumber: boolean;
  defaultCity?: string;
  defaultState?: string;
  defaultPostalCode?: string;
  error?: string;
}
export class SettingsTicketInputDefaults
  implements SettingsTicketInputDefaults
{
  constructor(init?: SettingsTicketInputDefaultsFormValues) {
    if (init) {
      this.id = init.id;
      this.capitalizeFirstCharacter =
        init.capitalizeFirstCharacter.toString() === "true";
      this.formatPhoneNumber = init.formatPhoneNumber.toString() === "true";
      this.defaultCity = init.defaultCity;
      this.defaultState = init.defaultState;
      this.defaultPostalCode = init.defaultPostalCode;
      this.error = init.error;
    }
  }
}
export class SettingsTicketInputDefaultsFormValues {
  id?: string = undefined;
  capitalizeFirstCharacter: boolean = false;
  formatPhoneNumber: boolean = false;
  defaultCity?: string = undefined;
  defaultState?: string = undefined;
  defaultPostalCode?: string = undefined;
  error?: string;

  constructor(
    settingsTicketInputDefaults?: SettingsTicketInputDefaultsFormValues
  ) {
    if (settingsTicketInputDefaults) {
      this.id = settingsTicketInputDefaults.id;
      this.capitalizeFirstCharacter =
        settingsTicketInputDefaults.capitalizeFirstCharacter;
      this.formatPhoneNumber = settingsTicketInputDefaults.formatPhoneNumber;
      this.defaultCity = settingsTicketInputDefaults.defaultCity;
      this.defaultState = settingsTicketInputDefaults.defaultState;
      this.defaultPostalCode = settingsTicketInputDefaults.defaultPostalCode;
      this.error = settingsTicketInputDefaults.error;
    }
  }
}

export interface SettingsWorkflow {
  id?: string;
  isCustomerInfoFirst: boolean;
  defaultTicketType: TicketType;
  numberIncrementOptions: string;
  allowCustomVehicle: boolean;
  error?: string;
}
export class SettingsWorkflow implements SettingsWorkflow {
  constructor(init?: SettingsWorkflowFormValues) {
    if (init) {
      this.id = init.id;
      this.isCustomerInfoFirst = init.isCustomerInfoFirst.toString() === "true";
      this.defaultTicketType = Number(init.defaultTicketType);
      this.numberIncrementOptions = init.numberIncrementOptions;
      this.allowCustomVehicle = init.allowCustomVehicle.toString() === "true";
      this.error = init.error;
    }
  }
}

export class SettingsWorkflowFormValues {
  id?: string = undefined;
  isCustomerInfoFirst: boolean = false;
  defaultTicketType: TicketType = 0;
  numberIncrementOptions: string = "";
  allowCustomVehicle: boolean = false;
  error?: string = undefined;

  constructor(settingsWorkflow?: SettingsWorkflowFormValues) {
    if (settingsWorkflow) {
      this.id = settingsWorkflow.id;
      this.isCustomerInfoFirst = settingsWorkflow.isCustomerInfoFirst;
      this.defaultTicketType = settingsWorkflow.defaultTicketType;
      this.numberIncrementOptions = settingsWorkflow.numberIncrementOptions;
      this.allowCustomVehicle = settingsWorkflow.allowCustomVehicle;
    }
  }
}
export interface TicketSession {
  id?: string;
  poNumber?: string;
  vehicle?: Vehicle;
  customer?: Customer;
  workflowStep?: TicketWorkflowSteps;
  description?: string;
  ticketType: TicketType;

  jobLabors: JobLabor[];
  labors: Labor[];

  ticketItems: TicketItem[];
  ticketPayments: TicketPaymentItem[];

  estimateDate?: Date;
  invoiceDate?: Date;
  promisedDate?: Date;

  estimateNumber?: number;
  invoiceNumber?: number;
  rateType: RateTypes;
  returnParts: boolean;
  showLaborHours: boolean;
  showSeparateCharges: boolean;
  isTaxExempt: boolean;
  estimatePaymentMethod?: string;
  estimateCharge?: number;

  authorizedContact?: string;
  otherAuthorizedPersonPhone?: string;

  notes?: string;

  salesTaxOptionType: SalesTaxOptionType;
  taxRate: number;

  totalLaborAmount: number;
  totalPartsAmount: number;
  totalMiscellaneousAmount: number;
  subTotalAmount: number;
  totalTaxes: number;
  totalAmount: number;
  amountDue: number;
  paymentReceived: number;
  alwaysMiscellaneousChargesAdded: boolean;
  manuallySorted?: boolean;
}
export class TicketSession implements TicketSession {
  constructor(init?: TicketSession) {
    if (init) {
      this.id = init.id;
      this.poNumber = init.poNumber;
      this.vehicle = init.vehicle;
      this.customer = init.customer;
      this.workflowStep = init.workflowStep;
      this.description = init.description;
      this.ticketType = init.ticketType;
      this.jobLabors = init.jobLabors;
      this.labors = init.labors;
      this.ticketItems = init.ticketItems;
      this.estimateDate = init.estimateDate ? new Date(init.estimateDate): new Date();
      this.invoiceDate = init.invoiceDate ? new Date(init.invoiceDate) : new Date();
      this.promisedDate = init.promisedDate
        ? new Date(init.promisedDate)
        : undefined;
      this.estimateNumber = init.estimateNumber
        ? Number(init.estimateNumber)
        : undefined;
      this.invoiceNumber = init.invoiceNumber
        ? Number(init.invoiceNumber)
        : undefined;
      this.rateType = init.rateType;
      this.returnParts = init.returnParts.toString() === "true";
      this.showLaborHours = init.showLaborHours.toString() === "true";
      this.showSeparateCharges = init.showSeparateCharges.toString() === "true";
      this.isTaxExempt = init.isTaxExempt.toString() === "true";
      this.estimatePaymentMethod = init.estimatePaymentMethod;
      this.estimateCharge = init.estimateCharge
        ? Number(init.estimateCharge)
        : undefined;
      this.authorizedContact = init.authorizedContact;
      this.otherAuthorizedPersonPhone = init.otherAuthorizedPersonPhone;
      this.notes = init.notes;
      this.salesTaxOptionType = init.salesTaxOptionType;
      this.taxRate = init.taxRate;
      this.totalLaborAmount = init.totalLaborAmount;
      this.totalPartsAmount = init.totalPartsAmount;
      this.totalMiscellaneousAmount = init.totalMiscellaneousAmount;
      this.subTotalAmount = init.subTotalAmount;
      this.totalTaxes = init.totalTaxes;
      this.totalAmount = init.totalAmount;
      this.amountDue = init.amountDue;
      this.ticketPayments = init.ticketPayments;
      this.paymentReceived = init.paymentReceived;
      this.alwaysMiscellaneousChargesAdded = init.alwaysMiscellaneousChargesAdded ? init.alwaysMiscellaneousChargesAdded.toString() === "true" : false;
      this.manuallySorted = init.manuallySorted ?? false;
    }
  }
}

export interface PONumber {
  value?: string;
  error?: string;
}

export class PONumber implements PONumber {
  value?: string;
  error?: string;
  constructor(poNumber?: PONumberFormValues) {
    if (poNumber) {
      this.value = poNumber.value;
      this.error = poNumber.error;
    }
  }
}

export class PONumberFormValues {
  value?: string;
  error?: string;
  constructor(init?: PONumberFormValues) {
    if (init) {
      this.value = init.value;
      this.error = init.error;
    }
  }
}

export interface AbandonedTickets {
  id: string;
  accessedDate: Date;
  ticketType: TicketType;
  ticketNumber?: number;
  staffId: string;
  staffName: string;
  vehicleDescription?: string;
  customerName?: string;
  poNumber?: string;
  workflowStep?: TicketWorkflowSteps;
}

export interface TicketItem {
  id?: string;
  technicianId?: string;
  jobId?: number;
  order?: number;
  description?: string;
  type?: TicketItemType;
  timeType?: TimeType;
  miscellaneousChargeId?: string;
  miscellaneousChargeVersion?: number;
  partId?: string;
  partCode?: string;
  vendorId?: string;
  unitPrice?: number;
  partMarkup?: number;
  quantity: number;
  rate?: number;
  subTotal?: number;
  laborRate?: string;
  isTaxable: boolean;
  taxRate?: number;
  tax?: number;
  total?: number;
  error?: string;
  jobLaborId?: number;
  packageJobId?: string;
}

export class TicketItem implements TicketItem {
  constructor(init?: TicketItemFormValues) {
    if (init) {
      this.id = init.id;
      this.technicianId = init.technicianId;
      this.order = init.order;
      this.description = init.description;
      this.type = init.type;
      this.miscellaneousChargeId = init.miscellaneousChargeId;
      this.miscellaneousChargeVersion = init.miscellaneousChargeVersion;
      this.partId = init.partId;
      this.partCode = init.partCode;
      this.vendorId = init.vendorId;
      this.unitPrice = init.unitPrice ? Number(init.unitPrice) : undefined;
      this.partMarkup = init.partMarkup;
      this.quantity = Number(init.quantity);
      this.rate = init.rate ? Number(init.rate) : undefined;
      this.subTotal = init.subTotal ? Number(init.subTotal) : undefined;
      this.laborRate = init.laborRate;
      this.isTaxable = init.isTaxable.toString() === "true";
      this.taxRate = init.taxRate;
      this.tax = init.tax;
      this.total = init.total;
      this.error = init.error;
      this.jobLaborId = init.jobLaborId;
      this.packageJobId = init.packageJobId;
    }
  }
}

export class TicketItemFormValues {
  id?: string = undefined;
  technicianId?: string = undefined;
  order?: number = undefined;
  description?: string = undefined;
  type?: TicketItemType = undefined;
  miscellaneousChargeId?: string = undefined;
  miscellaneousChargeVersion?: number = undefined;
  partId?: string = undefined;
  partCode?: string = undefined;
  vendorId?: string = undefined;
  unitPrice?: number = undefined;
  partMarkup?: number = undefined;
  quantity: number = 1;
  rate?: number = undefined;
  subTotal?: number = undefined;
  laborRate?: string = undefined;
  isTaxable: boolean = true;
  taxRate?: number = undefined;
  tax?: number = undefined;
  total?: number = undefined;
  error?: string = undefined;
  jobLaborId?: number = undefined;
  packageJobId?: string = undefined;

  constructor(init?: TicketItemFormValues) {
    if (init) {
      this.id = init.id;
      this.technicianId = init.technicianId;
      this.order = init.order;
      this.description = init.description;
      this.type = init.type;
      this.miscellaneousChargeId = init.miscellaneousChargeId;
      this.miscellaneousChargeVersion = init.miscellaneousChargeVersion;
      this.partId = init.partId;
      this.partCode = init.partCode;
      this.vendorId = init.vendorId;
      this.unitPrice = init.unitPrice;
      this.partMarkup = init.partMarkup;
      this.quantity = init.quantity;
      this.rate = init.rate;
      this.subTotal = init.subTotal;
      this.isTaxable = init.isTaxable.toString() === "true";
      this.taxRate = init.taxRate;
      this.tax = init.tax;
      this.total = init.total;
      this.error = init.error;
      this.jobLaborId = init.jobLaborId;
      this.packageJobId = init.packageJobId;
    }
  }
}

export interface TicketPaymentItem {
  id?: string;
  status?: PaymentStatus;
  paymentTypeId?: string;
  paymentType?: string;
  transactionType: TransactionType;
  refundedPaymentId?: string;
  amount?: number;
  createdDate?: Date;
  ticketCardTransactionId?: string;
  isAdd?: boolean;
  refunded:boolean;
  refundedAmount?: number;
  error?: string;
}
export class TicketPaymentItem implements TicketPaymentItem {
  constructor(init?: TicketPaymentItem) {
    if (init) {
      this.id = init.id;
      this.status = init.status;
      this.paymentTypeId = init.paymentTypeId;
      this.paymentType = init.paymentType;
      this.transactionType = init.transactionType;
      this.refundedPaymentId = init.refundedPaymentId;
      this.amount = init.amount ? Number(init.amount) : undefined;
      this.createdDate = init.createdDate;
      this.ticketCardTransactionId = init.ticketCardTransactionId;
      this.isAdd = init.isAdd;
      this.refunded = init.refunded;
      this.refundedAmount = init.refundedAmount;
      this.error = init.error;
    }
  }
}

export interface TicketFinalize {
  id?: string;
  returnParts: boolean;
  showLaborHours: boolean;
  showSeparateCharges: boolean;
  rateType: RateTypes;
  ticketType?: TicketType;
  estimateDate?: Date;
  invoiceDate?: Date;
  promisedDate?: Date;
  otherAuthorizedPerson?: string;
  otherAuthorizedPersonPhone?: string;
  notes?: string;
  estimatePaymentMethod?: string;
  estimateCharge?: number;
  ticketPayments?: TicketPaymentItem[];
  paymentReceived?: number;
  error?: string;
}

export class TicketFinalize implements TicketFinalize {
  constructor(ticket?: TicketFinalizeFormValues) {
    if (ticket) {
      this.id = ticket.id;
      this.ticketType = ticket.ticketType;
      this.estimateDate = ticket.estimateDate
        ? new Date(ticket.estimateDate)
        : undefined;
      this.invoiceDate = ticket.invoiceDate
        ? new Date(ticket.invoiceDate)
        : undefined;
      this.promisedDate = ticket.promisedDate
        ? new Date(ticket.promisedDate)
        : undefined;
      this.returnParts = ticket.returnParts.toString() === "true";
      this.showLaborHours = ticket.showLaborHours.toString() === "true";
      this.showSeparateCharges =
        ticket.showSeparateCharges.toString() === "true";
      this.rateType = ticket.rateType;
      this.otherAuthorizedPerson = ticket.otherAuthorizedPerson;
      this.otherAuthorizedPersonPhone = ticket.otherAuthorizedPersonPhone;
      this.notes = ticket.notes;
      this.estimatePaymentMethod = ticket.estimatePaymentMethod;
      this.estimateCharge = ticket.estimateCharge
        ? Number(ticket.estimateCharge)
        : undefined;
      this.ticketPayments = ticket.ticketPayments;
      this.paymentReceived = ticket.paymentReceived
        ? Number(ticket.paymentReceived)
        : undefined;
      this.error = ticket.error;
    }
  }
}

export class TicketFinalizeFormValues {
  id?: string = undefined;
  returnParts: boolean = false;
  showSeparateCharges: boolean = false;
  showLaborHours: boolean = false;
  rateType: RateTypes = RateTypes.Flat & RateTypes.Hourly;
  ticketType?: TicketType = undefined;
  estimateDate?: Date = undefined;
  invoiceDate?: Date = undefined;
  promisedDate?: Date = undefined;
  otherAuthorizedPerson?: string = undefined;
  otherAuthorizedPersonPhone?: string = undefined;
  notes?: string = undefined;
  estimatePaymentMethod?: string;
  estimateCharge?: number;
  ticketPayments?: TicketPaymentItem[] = [];
  paymentReceived?: number = undefined;
  error?: string;

  constructor(init?: TicketFinalizeFormValues) {
    if (init) {
      this.id = init.id;
      this.ticketType = init.ticketType;
      this.estimateDate = init.estimateDate
        ? new Date(init.estimateDate)
        : undefined;
      this.invoiceDate = init.invoiceDate
        ? new Date(init.invoiceDate)
        : undefined;
      this.promisedDate = init.promisedDate
        ? new Date(init.promisedDate)
        : undefined;
      this.returnParts = init.returnParts.toString() === "true";
      this.showLaborHours = init.showLaborHours.toString() === "true";
      this.showSeparateCharges = init.showSeparateCharges.toString() === "true";
      this.rateType = init.rateType;
      this.otherAuthorizedPerson = init.otherAuthorizedPerson;
      this.otherAuthorizedPersonPhone = init.otherAuthorizedPersonPhone;
      this.notes = init.notes;
      this.estimatePaymentMethod = init.estimatePaymentMethod;
      this.estimateCharge = init.estimateCharge;
      this.ticketPayments = init.ticketPayments;
      this.paymentReceived = init.paymentReceived;
      this.error = init.error;
    }
  }
}

export interface RecentTickets {
  ticketId?: string;
  customerId?: string;
  isCommercial?: boolean;
  businessName?: string;
  firstName?: string;
  lastName?: string;
  addressLine1?: string;
  addressLine2?: string;
  city?: string;
  state?: string;
  postalCode?: string;
  primaryNumber?: string;
  secondaryNumber?: string;
  email?: string;
  customerDateCreated?: Date;

  vehicleId?: string;
  year?: number;
  make?: string;
  model?: string;
  engine?: string;
  tag?: string;
  mileage?: number;
  vin?: string;
  vehicleNumber?: string;
  vehicleCount?: number;

  invoiceNumber?: number;
  estimateNumber?: number;
  ticketType?: TicketType;
  estimateDate?: Date;
  invoiceDate?: Date;
  total?: number;
  poNumber?: string;
  description?: string;
  ticketCount?: number;
}

export interface CustomerTicket {
  id?: string;
  createdDate?: Date;
  ticketType: TicketType;
  invoiceNumber?: number;
  estimateNumber?: number;
  vehicleId?: string;
  year?: number;
  makeName?: string;
  modelName?: string;
  description?: string;
  poNumber?: string;
  total?: number;
}

export interface CreateTicket {
  ticketId: string;
  customerId: string;
  vehicleId?: string;
}

export class CreateTicket implements CreateTicket {
  constructor(init?: CreateTicketFormValues) {
    if (init) {
      this.customerId = init.customerId;
      this.ticketId = init.ticketId;
      this.vehicleId = init.vehicleId;
    }
  }
}

export class CreateTicketFormValues {
  ticketId: string = "";
  customerId: string = "";
  vehicleId?: string = undefined;

  constructor(createTicket?: CreateTicketFormValues) {
    if (createTicket) {
      this.customerId = createTicket.customerId;
      this.ticketId = createTicket.ticketId;
      this.vehicleId = createTicket.vehicleId;
    }
  }
}

export interface TicketCardTransaction {
  ticketId?: string;
  ticketPaymentId?: string;
  ticketCardTransactionId?: string;
  transactionType?: string;
  cardNumber?: string;
  cardType?: string;
  cardEntryMethod?: string;
  transactionDate?: Date;
  cardHolderName?: string;
  bin?: string;
  batchNumber?: string;
  terminalId?: string;
  authorizationCode?: string;
  signature?: any;
  amount?: number;
}

export interface TicketHistoryItem {
  id: string;
  ticketId: string;
  createdDate?: Date;
  staffName?: string;
  action?: string;
  description?: string;
  ticketItemType?: string;
  partCode?: string;
}

export class TicketHistoryItem implements TicketHistoryItem {
  constructor(init?: TicketHistoryItem) {
    if (init) {
      this.id = init.id;
      this.ticketId = init.ticketId;
      this.createdDate = init.createdDate;
      this.staffName = init.staffName;
      this.action = init.action;
      this.description = init.description;
      this.ticketItemType = init.ticketItemType;
      this.partCode = init.partCode;
    }
  }
}

export interface PdfFile {
  File: any;
}
