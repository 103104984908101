import { observer } from "mobx-react-lite";
import { Button, Divider, Dropdown, DropdownProps, Grid, Input, InputProps, Message, Table } from "semantic-ui-react";
import { useTranslation } from "react-i18next";
import { useStore } from "../../../app/stores/store";
import { Component, useEffect, useMemo, useRef, useState } from "react";
import { PagingParams } from "../../../app/models/pagination";
import { format } from "date-fns";
import MyPaging from "../../../app/common/form/MyPaging";
import { searchOptions } from "../../../app/common/options/searchOptions";
import { SearchType } from "../../../app/models/enums";

interface Props {
  clientId: string;
}

export default observer(function DeletedTicket({ clientId }: Props) {
  const { t } = useTranslation(["common", "companySettings"]);
  const { ticketDataRecoveryStore } = useStore();
  const filterTypeDropdownRef = useRef<Component<DropdownProps>>();
  const filterValueInputRef = useRef<Component<InputProps>>();
  const filterOptions = useMemo(
    () => searchOptions.slice().filter(op => op.key == SearchType.InvoiceNumber || op.key == SearchType.EstimateNumber || op.key == SearchType.PONumber),
    []
  );
  const {
    getDeletedTickets,
    restoreTicket,
    pagingParams,
    pagination,
    setPagingParams,
    DeletedTicketsRegistry,
    loadDeletedTicket,
    predicate,
    loading,
    setPredicate,
    clearFilters
  } = ticketDataRecoveryStore;

  useEffect(() => {

    setPredicate("ClientId", clientId);
    loadDeletedTicket(clientId);
  }, [clientId]);

  function listOnClick(value: number) {
    if (value > 0 && pagination && value < pagination?.totalPages + 1) {
      setPagingParams(new PagingParams(value));
      DeletedTicketsRegistry.clear();
      loadDeletedTicket(clientId);
    }
  }
  return (
    <>
      <Grid className='w-100' stackable>
        <Grid.Row columns={2}>
          <Grid.Column>
            <label>{"Filter By:"}</label>{" "}
            <Dropdown
              id="filterTicketsDropdown"
              button
              basic
              floating
              options={filterOptions}
              onChange={(e, d) => {
                setPredicate('filterType', SearchType[d.value as number])
              }}
            />
            <Input
              name='filterValue'
              type="text"
              value={predicate.get('filterValue') ?? ""}
              onChange={(e, d) => setPredicate("filterValue", d.value)}
            ></Input>
          </Grid.Column>
          <Grid.Column className="d-flex justify-content-end p-0">
            <Button
              color="blue"
              className="save_button"
              icon="filter"
              content={"Filter Tickets"}
              loading={loading}
              onClick={() => loadDeletedTicket(clientId)}
            />
            {(predicate.get('filterType') || predicate.get('filterValue')) && (
              <Button
                color="grey"
                className="save_button"
                icon="cancel"
                content={"Clear Filters"}
                disabled={loading}
                onClick={() => {
                  clearFilters();
                  loadDeletedTicket(clientId);
                }}
              />
            )}
          </Grid.Column>
        </Grid.Row>
      </Grid>
      <Divider />
      <Table celled>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>Ticket Date</Table.HeaderCell>
            <Table.HeaderCell>Type</Table.HeaderCell>
            <Table.HeaderCell>Ticket Number</Table.HeaderCell>
            <Table.HeaderCell>Po Number</Table.HeaderCell>
            <Table.HeaderCell>Customer Name</Table.HeaderCell>
            <Table.HeaderCell>Vehicle</Table.HeaderCell>
            <Table.HeaderCell>Deleted Date</Table.HeaderCell>
            <Table.HeaderCell>Deleted By</Table.HeaderCell>
            <Table.HeaderCell>Restore</Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {getDeletedTickets && getDeletedTickets.length > 0 ? (
            getDeletedTickets.map((x) => (
              <Table.Row key={`ticket.${x.id}`}>
                <Table.Cell>{format(x.date, "P p")}</Table.Cell>
                <Table.Cell>{x.type}</Table.Cell>
                <Table.Cell>{x.ticketNumber}</Table.Cell>
                <Table.Cell>{x.poNumber}</Table.Cell>
                <Table.Cell>{`${x.lastName}, ${x.firstName}`}</Table.Cell>
                <Table.Cell>{`${x.year} ${x.make} ${x.model}`}</Table.Cell>
                <Table.Cell>{format(x.deletedDate, "P p")}</Table.Cell>
                <Table.Cell>{x.deletedBy}</Table.Cell>
                <Table.Cell>
                  {" "}
                  <Button
                    className="save_button"
                    icon="check"
                    content="Restore"
                    type="submit"
                    onClick={() => restoreTicket(x.id)}
                  />
                </Table.Cell>
              </Table.Row>
            ))
          ) : (
            <Table.Row key={"noItems"}>
              <Table.Cell width="9" colSpan={9}>
                <Message
                  color="yellow"
                  className="textAlignCenter fontStyleItalic"
                >
                  {t("grid_noItems", { ns: "common" })}
                </Message>
              </Table.Cell>
            </Table.Row>
          )}
        </Table.Body>
        <Table.Footer>
          <Table.Row>
            <Table.HeaderCell colSpan="9">
              <MyPaging
                itemCount={pagination ? pagination.totalItems : 10}
                pageSize={pagingParams.pageSize}
                currentPage={pagination ? pagination.currentPage : 1}
                onClick={listOnClick}
                onChange={listOnClick}
                floated="right"
              ></MyPaging>
            </Table.HeaderCell>
          </Table.Row>
        </Table.Footer>
      </Table>
    </>
  );
});
