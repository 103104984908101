import { observer } from "mobx-react-lite";
import { Button, Dropdown, Grid, Message, Table } from "semantic-ui-react";
import { useTranslation } from "react-i18next";
import { useStore } from "../../../app/stores/store";
import { useEffect, useState } from "react";
import { PagingParams } from "../../../app/models/pagination";
import { format } from "date-fns";
import MyPaging from "../../../app/common/form/MyPaging";
import { DeletedTicketSessionSortKeys } from "../../../app/models/systemAdmin";
import { OrderDirection } from "../../../app/models/ticket";
import { deletedTicketSessionSortByOptions, sortOrderOptions } from "../../../app/common/options/option";

interface Props {
  clientId: string;
}

export default observer(function DeletedTicketSession({ clientId }: Props) {
  const { t } = useTranslation(["common", "companySettings"]);
  const { ticketSessionDataRecoveryStore } = useStore();
  const [sortBy, setSortBy] = useState<DeletedTicketSessionSortKeys | undefined>(undefined);
  const [orderDir, setOrderDir] = useState<OrderDirection | undefined>(OrderDirection.Descending);

  const {
    getDeletedTicketSessions,
    restoreTicketSession,
    pagingParams,
    pagination,
    setPagingParams,
    DeletedTicketSessionsRegistry,
    loadDeletedTicketSessions,
    setPredicate
  } = ticketSessionDataRecoveryStore;

  useEffect(() => {

      setPredicate("ClientId", clientId);
      loadDeletedTicketSessions(clientId);
  }, [loadDeletedTicketSessions, clientId]);

  useEffect(() => {
    setPredicate("ClientId", clientId);
    loadDeletedTicketSessions(clientId);
  }, [sortBy, orderDir])

  function listOnClick(value: number) {
    if (value > 0 && pagination && value < pagination?.totalPages + 1) {
      setPagingParams(new PagingParams(value));
      DeletedTicketSessionsRegistry.clear();
      loadDeletedTicketSessions(clientId);
    }
  }
  return (
    <>
      <Grid stackable>
        <Grid.Row columns={3}>
          <Grid.Column>
            <div className="d-flex">
                <label className="myLabel col-4 col-md-3 py-2">{t("sortBy", { ns: "common" })}:</label>
                <Dropdown
                  float="right"
                  placeholder={deletedTicketSessionSortByOptions[0].text}
                  name={"sortTicketSessionBy"}
                  fluid
                  selection
                  clearable
                  options={deletedTicketSessionSortByOptions}
                  value={sortBy}
                  onChange={(e, d) => {
                    setSortBy(d.value ? d.value as DeletedTicketSessionSortKeys : undefined);
                    setPredicate('sortTicketSessionBy', d.value?.toString())
                  }}
                />
              </div>
            </Grid.Column>
            <Grid.Column>              
              <div className="d-flex">
                <label className="myLabel col-4 col-md-3 py-2">{t("sortOrder", { ns: "common" })}:</label>
                <Dropdown
                  placeholder={deletedTicketSessionSortByOptions[0].orderOpts[0].text}
                  name={"orderDir"}
                  fluid
                  selection
                  clearable
                  options={sortBy ? deletedTicketSessionSortByOptions.find(sk => sk.value == sortBy)?.orderOpts : deletedTicketSessionSortByOptions[0].orderOpts}
                  value={orderDir}
                  onChange={(e, d) => {
                    setOrderDir(d.value ? d.value as OrderDirection : undefined);
                    setPredicate('orderDir', d.value?.toString())
                  }}
                />
            </div>
          </Grid.Column>
        </Grid.Row>
      </Grid>
      <Table celled>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>Ticket Date</Table.HeaderCell>
            <Table.HeaderCell>Client Id</Table.HeaderCell>
            <Table.HeaderCell>Type</Table.HeaderCell>
            <Table.HeaderCell>Ticket Number</Table.HeaderCell>
            <Table.HeaderCell>Po Number</Table.HeaderCell>
            <Table.HeaderCell>Customer Name</Table.HeaderCell>
            <Table.HeaderCell>Vehicle</Table.HeaderCell>
            <Table.HeaderCell>Deleted Date</Table.HeaderCell>
            <Table.HeaderCell >Deleted By</Table.HeaderCell>
            <Table.HeaderCell>Restore</Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {getDeletedTicketSessions && getDeletedTicketSessions.length > 0 ? (
            getDeletedTicketSessions.map((x) => (
              <Table.Row key={`ticket.${x.id}`}>
                <Table.Cell>{format(x.date, "P p")}</Table.Cell>
                <Table.Cell> {x.clientId}</Table.Cell>
                <Table.Cell>{x.type}</Table.Cell>
                <Table.Cell>{x.ticketNumber}</Table.Cell>
                <Table.Cell>{x.poNumber}</Table.Cell>
                <Table.Cell>{x.customerName}</Table.Cell>
                <Table.Cell>{x.vehicleDescription}</Table.Cell>
                <Table.Cell>{format(x.deletedDate, "P p")}</Table.Cell>
                <Table.Cell>{x.deletedBy}</Table.Cell>
                <Table.Cell>
                  {" "}
                  <Button
                    className="save_button"
                    icon="check"
                    content="Restore"
                    type="submit"
                    onClick={() => restoreTicketSession(x.id)}
                  />
                </Table.Cell>
              </Table.Row>
            ))
          ) : (
            <Table.Row key={"noItems"}>
              <Table.Cell width="10" colSpan={10}>
                <Message
                  color="yellow"
                  className="textAlignCenter fontStyleItalic"
                >
                  {t("grid_noItems", { ns: "common" })}
                </Message>
              </Table.Cell>
            </Table.Row>
          )}
        </Table.Body>
        <Table.Footer>
          <Table.Row>
            <Table.HeaderCell colSpan="10">
              <MyPaging
                itemCount={pagination ? pagination.totalItems : 25}
                pageSize={pagingParams.pageSize}
                currentPage={pagination ? pagination.currentPage : 1}
                onClick={listOnClick}
                onChange={listOnClick}
                floated="right"
              ></MyPaging>
            </Table.HeaderCell>
          </Table.Row>
        </Table.Footer>
      </Table>
    </>
  );
});
