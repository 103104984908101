import { observer } from "mobx-react-lite";
import React from "react";
import {
  Container,
  Grid,
  List,
  Segment,
} from "semantic-ui-react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

export default observer(function Footer() {
  const { t } = useTranslation(["common"]);
  let currentYear = new Date().getFullYear();

  return (
    <Segment className="footer" inverted>
      <Container className="full-width">
        <Grid columns={2} stackable inverted>
          <Grid.Column className="left-footer-column">
            <List horizontal inverted divided size="small">
              <List.Item>
                {`${t("footer.copyright", { ns: "common" })} ${currentYear} `}
                {`${t("footer.bydowellsystems", { ns: "common" })} `}
              </List.Item>
              <List.Item>
                {t("footer.allrightsreserved", { ns: "common" })}
              </List.Item>
              <List.Item>
                {t("telephone", { ns: "common" })}: (800) 487-0279
              </List.Item>
            </List>
          </Grid.Column>
          <Grid.Column className="right-footer-column">
            <List horizontal inverted divided link size="small">
              <List.Item className="footer-link" as={Link} to="/">
                {t("home", { ns: "common" })}
              </List.Item>
              <List.Item className="footer-link" as={Link} to="https://www.autolaborexperts.com/">
                {t("learnMore", { ns: "common" })}
              </List.Item>
              <List.Item className="footer-link" as={Link} to="https://www.autolaborexperts.com/ContactUs">
                {t("feedback", { ns: "common" })}
              </List.Item>
              <List.Item className="footer-link" as={Link} to="/terms">
                {`${t("terms", { ns: "common" })} ${t("and", { ns: "common" })} ${t("conditions", { ns: "common" })}`}
              </List.Item>
              <List.Item className="footer-link" as={Link} to="/privacy">
                {t("privacy", { ns: "common" })}
              </List.Item>
              <List.Item className="footer-link" as={Link} to="/eula">
                {t("eula", { ns: "common" })}
              </List.Item>
              <List.Item className="footer-link" as={Link} to="https://www.autolaborexperts.com/static/media/WRS.3284cc38.exe">
                {t("rst", { ns: "common" })}
              </List.Item>
            </List>
          </Grid.Column>
        </Grid>
      </Container>
    </Segment>
  );
});
