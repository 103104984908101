import { observer } from "mobx-react-lite";
import { Button, Message, Table } from "semantic-ui-react";
import { useTranslation } from "react-i18next";
import { useStore } from "../../../app/stores/store";
import { useEffect } from "react";
import { PagingParams } from "../../../app/models/pagination";
import { format } from "date-fns";
import MyPaging from "../../../app/common/form/MyPaging";
import { error } from "console";

interface Props {
  clientId: string;
}
export default observer(function DeletedCustomer({ clientId }: Props) {
  const { t } = useTranslation(["common", "companySettings"]);
  const { customerDataRecoveryStore } = useStore();

  const {
    getDeletedCustomers,
    loadDeletedCustomers,
    restoreCustomer,
    pagination,
    DeletedCustomersRegistry,
    setPagingParams,
    pagingParams,
    setPredicate
  } = customerDataRecoveryStore;

  useEffect(() => {
    setPredicate("clientId", clientId);
    loadDeletedCustomers(clientId ?? "");
  }, [loadDeletedCustomers, getDeletedCustomers.length,  clientId]);

  function listOnClick(value: number) {
    if (value > 0 && pagination && value < pagination?.totalPages + 1) {
      setPagingParams(new PagingParams(value));
      DeletedCustomersRegistry.clear();
      loadDeletedCustomers(clientId);
    }
  }
  return (
    <Table celled>
      <Table.Header>
        <Table.Row>
          <Table.HeaderCell>Customer Id</Table.HeaderCell>
          <Table.HeaderCell>Name</Table.HeaderCell>
          <Table.HeaderCell>Company</Table.HeaderCell>
          <Table.HeaderCell>Ticket Count</Table.HeaderCell>
          <Table.HeaderCell>Date Deleted</Table.HeaderCell>
          <Table.HeaderCell>Deleted By</Table.HeaderCell>
          <Table.HeaderCell>Restore</Table.HeaderCell>
        </Table.Row>
      </Table.Header>
      <Table.Body>
        {getDeletedCustomers && getDeletedCustomers.length > 0 ? (
          getDeletedCustomers.map((x) => (
            <Table.Row key={`customer.${x.customerId}`}>
              <Table.Cell>{x.customerId}</Table.Cell>
              <Table.Cell>{x.firstName + " " + x.lastName}</Table.Cell>
              <Table.Cell>{x.company}</Table.Cell>
              <Table.Cell>{x.tickets}</Table.Cell>
              <Table.Cell>{format(x.deletedDate, "P p")}</Table.Cell>
              <Table.Cell>{x.deletedBy}</Table.Cell>
              <Table.Cell>
                {" "}
                <Button
                  className="save_button"
                  icon="check"
                  content="Restore"
                  type="submit"
                  onClick={() =>
                    restoreCustomer(x.customerId)
                  }
                />
              </Table.Cell>
            </Table.Row>
          ))
        ) : (
          <Table.Row key={"noItems"}>
            <Table.Cell width="7" colSpan={7}>
              <Message
                color="yellow"
                className="textAlignCenter fontStyleItalic"
              >
                {t("grid_noItems", { ns: "common" })}
              </Message>
            </Table.Cell>
          </Table.Row>
        )}
      </Table.Body>
      <Table.Footer>
        <Table.Row>
          <Table.HeaderCell colSpan="7">
            <MyPaging
              itemCount={pagination ? pagination.totalItems : 25}
              pageSize={pagingParams.pageSize}
              currentPage={pagination ? pagination.currentPage : 1}
              onClick={listOnClick}
              onChange={listOnClick}
              floated="right"
            ></MyPaging>
          </Table.HeaderCell>
        </Table.Row>
      </Table.Footer>
    </Table>
  );
});
