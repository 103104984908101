import React, { Fragment, useEffect, useState } from "react";
import {
  Button,
  Icon,
  Card,
  Divider,
  Checkbox,
  Table,
  Message,
} from "semantic-ui-react";
import { useTranslation } from "react-i18next";
import { useStore } from "../../../app/stores/store";
import PONumberForm from "./modals/PONumberForm";
import { observer } from "mobx-react-lite";
import {
  formatCurrency,
  getNegative,
  getComboColor,
} from "../../../app/common/util/functions";
import {
  SalesTaxOptionType,
  TicketItemType,
  TicketType,
  TicketWorkflowSteps,
} from "../../../app/models/enums";

interface Props {
  workflowStep: TicketWorkflowSteps;
  autoCapitalizeFirstLetter: boolean;
  showTaxUpdate?: boolean;
  settingsTaxRate?: number;
  settingsSalesTaxOptions?: SalesTaxOptionType;
  originalSalesTaxOptions?: SalesTaxOptionType;
  originalTaxRate?: number;
}

export default observer(function TicketDisplay({
  workflowStep,
  autoCapitalizeFirstLetter,
  settingsTaxRate,
  settingsSalesTaxOptions,
  showTaxUpdate,
  originalSalesTaxOptions,
  originalTaxRate,
}: Props) {
  const { t } = useTranslation(["common", "ticketSettings"]);
  const { modalStore, vehicleStore, customerStore, ticketSessionStore } =
    useStore();
  const { selectedVehicle } = vehicleStore;

  const { selectedCustomer } = customerStore;

  const {
    selectedTicketSession,
    setSelectedIsTaxExempt,
    calculateLaborAmount,
    jobLaborsCompleted,
    poNumber,
    calculateTotals,
    updateTaxRates,
    updateTicketSession,
  } = ticketSessionStore;

  useEffect(() => {
    calculateTotals();
  }, [
    selectedTicketSession?.isTaxExempt,
    selectedTicketSession?.taxRate,
    selectedTicketSession?.salesTaxOptionType,
    calculateTotals,
  ]);

  const [taxDisplay, setTaxDisplay] = useState<number>(
    selectedTicketSession?.totalTaxes ?? 0
  );

  useEffect(() => {
    setTaxDisplay(selectedTicketSession?.totalTaxes ?? 0.0);
  }, [selectedTicketSession?.totalTaxes]);

  const laborItemIds = jobLaborsCompleted.map(j => j.id);
  const jobLaborIds = jobLaborsCompleted.map(j => j.jobLaborId);
  const jobsInSession = (selectedTicketSession?.ticketItems ?? [])
    .filter(ti => ti.partId === null && ti.miscellaneousChargeId === null && !laborItemIds.includes(ti.id) && !jobLaborIds.includes(ti.jobLaborId));

  const calcSessionLabor = () => jobsInSession.filter(ti => ti.subTotal)
    .reduce((sum, item) => sum + (item.subTotal ?? 0), 0);

  return (
    <Card fluid key="ticket">
      <Card.Content>
        <Card.Header as="h2">{t("ticket", { ns: "tickets" })}</Card.Header>
        <Divider />
        <Card.Description>
          {workflowStep === TicketWorkflowSteps.Summary &&
            selectedTicketSession && (
              <>
                <Checkbox
                  label={t("tax_exempt", { ns: "customer" })}
                  id="taxExempt"
                  name="taxExempt"
                  defaultChecked={selectedTicketSession.isTaxExempt}
                  onClick={(e, d) => {
                    setSelectedIsTaxExempt(d.checked);
                    //if (setTax) setTax(selectedTicketSession.totalTaxes);

                    if (workflowStep && selectedTicketSession?.id) {
                      //store data

                      updateTicketSession(
                        workflowStep,
                        selectedTicketSession?.id,
                        selectedVehicle,
                        selectedCustomer
                      );
                    }
                  }}
                ></Checkbox>
                <Table unstackable width={"100%"} basic="very">
                  <Table.Body>
                    <Table.Row key={"row1"}>
                      <Table.Cell style={{ color: "green" }}>
                        {t("tech.labor", { ns: "miscSettings" })}:
                      </Table.Cell>
                      <Table.Cell
                        textAlign="right"
                        style={{
                          color: getComboColor(
                            selectedTicketSession?.totalLaborAmount ?? 0.0,
                            TicketItemType.Labor
                          ),
                        }}
                      >
                        {formatCurrency(
                          selectedTicketSession?.totalLaborAmount ?? 0.0
                        )}
                      </Table.Cell>
                      <Table.Cell>
                        {t("estimate.subtotal", { ns: "tickets" })}:
                      </Table.Cell>
                      <Table.Cell
                        textAlign="right"
                        style={{
                          color: getNegative(
                            selectedTicketSession.subTotalAmount ?? 0.0
                          ),
                        }}
                      >
                        {formatCurrency(
                          selectedTicketSession.subTotalAmount ?? 0.0
                        )}
                      </Table.Cell>
                    </Table.Row>
                    <Table.Row key={"row2"}>
                      <Table.Cell style={{ color: "blue" }}>
                        {t("estimate.parts", { ns: "tickets" })}:
                      </Table.Cell>
                      <Table.Cell
                        textAlign="right"
                        style={{
                          color: getComboColor(
                            selectedTicketSession.totalPartsAmount ?? 0.0,
                            TicketItemType.Parts
                          ),
                        }}
                      >
                        {formatCurrency(
                          selectedTicketSession.totalPartsAmount ?? 0.0
                        )}
                      </Table.Cell>
                      <Table.Cell>
                        {t("estimate.tax", { ns: "tickets" })}:
                      </Table.Cell>
                      <Table.Cell
                        textAlign="right"
                        style={{ color: getNegative(taxDisplay) }}
                      >
                        {formatCurrency(taxDisplay)}
                      </Table.Cell>
                    </Table.Row>
                    <Table.Row key={"row3"}>
                      <Table.Cell>
                        {t("estimate.misc", { ns: "tickets" })}:
                      </Table.Cell>
                      <Table.Cell
                        textAlign="right"
                        style={{
                          color: getNegative(
                            selectedTicketSession.totalMiscellaneousAmount ??
                              0.0
                          ),
                        }}
                      >
                        {formatCurrency(
                          selectedTicketSession.totalMiscellaneousAmount ?? 0.0
                        )}
                      </Table.Cell>
                      <Table.Cell>
                        {t("estimate.total", { ns: "tickets" })}:
                      </Table.Cell>
                      <Table.Cell
                        textAlign="right"
                        style={{
                          color: getNegative(
                            selectedTicketSession.totalAmount ?? 0.0
                          ),
                        }}
                      >
                        {formatCurrency(
                          selectedTicketSession.totalAmount ?? 0.0
                        )}
                      </Table.Cell>
                    </Table.Row>
                  </Table.Body>
                </Table>
                {showTaxUpdate && (
                  <Message info>
                    <Checkbox
                      label={t("useNewTaxRate", { ns: "tickets" })}
                      id="updateTaxRate"
                      name="updateTaxRate"
                      onClick={(e, d) => {
                        updateTaxRates(
                          (d.checked ? settingsTaxRate : originalTaxRate) ??
                            0.0,
                          (d.checked
                            ? settingsSalesTaxOptions
                            : originalSalesTaxOptions) ??
                            SalesTaxOptionType.Parts
                        );
                        setTaxDisplay(selectedTicketSession.totalTaxes);
                      }}
                    ></Checkbox>
                  </Message>
                )}
              </>
            )}
          {jobLaborsCompleted &&
            workflowStep !== TicketWorkflowSteps.Summary &&
            jobLaborsCompleted.length > 0 && (
              <>
                {t("jobs", { ns: "tickets" })}:
                <br />
                {jobLaborsCompleted.map((labor, index) => (
                  <Fragment key={`labor.${labor.id}`}>
                    <Icon name="wrench" key={`labor.${labor.id}`} />
                    {labor.job?.job}
                    <br></br>
                  </Fragment>
                ))}
                <>
                  {t("estimate.labor", { ns: "tickets" })}:{" "}
                  {formatCurrency(calculateLaborAmount())}
                </>
                <span className="card-span-right">
                  {t("estimate.total", { ns: "tickets" })}:{" "}
                  {selectedTicketSession?.totalAmount
                    ? formatCurrency(selectedTicketSession?.totalAmount ?? 0.0)
                    : "--"}
                </span>
                <br></br>
              </>
            )}
          {selectedTicketSession?.invoiceNumber &&
            selectedTicketSession.ticketType == TicketType.Invoice && (
              <>
                {t("estimate.invoice", { ns: "tickets" })}#: {" "}
                {selectedTicketSession.invoiceNumber}<br />
              </>
            )}
          {selectedTicketSession?.estimateNumber &&
            selectedTicketSession.ticketType == TicketType.Estimate && (
              <>
                {t("estimate.est", { ns: "tickets" })}#: {" "} 
                {selectedTicketSession.estimateNumber} <br />
              </>
            )}
          {`${t("estimate.po", { ns: "tickets" })}: `}
          {poNumber?.value ? (
            poNumber.value
          ) : (
            <i>{t("ticket_defaults.blank", { ns: "ticketSettings" })}</i>
          )}
          <Button
            floated="right"
            color="grey"
            onClick={() =>
              modalStore.openModal(
                <PONumberForm
                  autoCapitalizeFirstLetter={autoCapitalizeFirstLetter}
                  updateData={true}
                  ticketWorkflowStep={workflowStep}
                  ticketId={selectedTicketSession?.id ?? ""}
                />
              )
            }
          >
            <Icon name="edit" />
            {t("edit", { ns: "common" })}
          </Button>
        </Card.Description>
      </Card.Content>
    </Card>
  );
});
