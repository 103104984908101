import { Table } from 'semantic-ui-react';
import Icon from './change-order-icon-3.png';
import { useMediaQuery } from 'react-responsive';

interface DraggableTableRowProps {
  id: string;
  order: number;
  children: React.ReactNode;
  onDrop?: (id: string, order: number, newOrder: number, dropId: string) => void;
}

export function DraggableTableRow(props: DraggableTableRowProps) {
  const isMobile = useMediaQuery({ maxWidth: 767 })

  function onDragStart(ev: DragEvent) {
    ev.dataTransfer?.setData("id", props.id);
    ev.dataTransfer?.setData("order", props.order.toString());
  }

  function onDrop(ev: DragEvent, dropId: string, dropOrder: number) {
    const id = ev.dataTransfer?.getData("id") as string;
    const order = parseInt(ev.dataTransfer?.getData("order") as string)
    props.onDrop?.(id, order, dropOrder, dropId);
  }

  function onDragOver(e: DragEvent) {e.preventDefault()}

  return (
    <Table.Row
      key={props.id}
      draggable
      onDragStart={onDragStart}
      onDragOver={onDragOver}
      onDrop={(e: DragEvent) => onDrop(e, props.id, props.order)}
    >
      {!isMobile && (
        <Table.Cell className="middle aligned">
          <img src={Icon} alt="reorder" height={20} style={{cursor: 'grab'}} />
        </Table.Cell>
      )}
      {props.children}
    </Table.Row>
  );
}