import { observer } from "mobx-react-lite";
import React, { useEffect } from "react";
import { Header, Button, Icon, Grid, Form, Divider } from "semantic-ui-react";
import { useTranslation } from "react-i18next";
import * as Yup from "yup";
import { Formik, FormikErrors } from "formik";
import { useStore } from "../../../app/stores/store";
import { VehicleFormValues } from "../../../app/models/vehicle";
import PageHeader from "../../../app/layout/PageHeader";
import MyDropdownInput from "../../../app/common/form/MyDropdownInput";
import Sidebar from "./Sidebar";
import { useNavigate, useParams } from "react-router-dom";
import { TicketWorkflowSteps } from "../../../app/models/enums";
import PotentialCustomerMatchingMessage from "../../customers/PotentialCustomerMatchingMessage";
import QuickVinLookupForm from "./modals/QuickVinLookupForm";
import { useMediaQuery } from "react-responsive";

interface Props {
  autoCapitalizeFirstLetter: boolean;
  workflowStep: TicketWorkflowSteps;
  quickVinIsActive: boolean;
}

export default observer(function VehicleSelection({
  autoCapitalizeFirstLetter,
  workflowStep,
  quickVinIsActive,
}: Props) {
  const { t } = useTranslation(["common", "recentTickets"]);
  const navigate = useNavigate();
  const isMobile = useMediaQuery({ maxWidth: 767 })

  const {
    customerStore,
    vehicleStore,
    ticketSessionStore,
    modalStore,
    ticketStore,
  } = useStore();

  const { selectedCustomer } = customerStore;
  const {
    updateTicketSession,
    setSelectedTicketCustomer,
    setSelectedTicketVehicle,
  } = ticketSessionStore;
  const { selectedWorkflow } = ticketStore;

  const { id } = useParams<{ id: string }>();
  const { complete } = useParams<{ complete: string }>();

  const {
    yearList,
    loadYearListWithCustom,
    makeList,
    loadDsiMakeListWithCustom,
    modelList,
    loadDsiModelListWithCustom,
    engineList,
    loadDsiEngineListWithCustom,
    setSelectedVehicleYear,
    setSelectedVehicleMake,
    setSelectedVehicleModelId,
    setSelectedVehicleModel,
    setSelectedVehicleEngineId,
    setSelectedVehicleEngine,
    setSelectedVehicleIsCustom,
    isYearSelected,
    isMakeSelected,
    isModelSelected,
    resetMakeList,
    resetModelList,
    resetEngineList,
    selectedVehicle,
    setSelectedVehicle,
  } = vehicleStore;

  function handleFormSubmit(values: VehicleFormValues) {
    if (selectedCustomer) setSelectedTicketCustomer(selectedCustomer);
    setSelectedTicketVehicle(selectedVehicle);
    setSelectedVehicle(selectedVehicle);

    const action = () =>
      updateTicketSession(
        TicketWorkflowSteps.Vehicle,
        id ?? "",
      );

    action()
      .then(() => {
        navigate(`/ticket/jobCategory/${id}/${complete}`);
      })
      .catch((errors) => console.log(errors));
  }

  enum Reset {
    Year = 0,
    Make = 1,
    Model = 2,
    Engine = 3,
  }

  useEffect(() => {
    if (yearList.length <= 1) loadYearListWithCustom();
  }, [yearList.length, loadYearListWithCustom]);

  function handleLoadMake(
    year: any,
    setFieldValue: (
      field: string,
      value: any,
      shouldValidate?: boolean | undefined
    ) => Promise<void | FormikErrors<VehicleFormValues>>,
    setFieldTouched: (
      field: string,
      isTouched?: boolean | undefined,
      shouldValidate?: boolean | undefined
    ) => Promise<void | FormikErrors<VehicleFormValues>>
  ) {
    if (!isNaN(parseInt(year))) {
      setSelectedVehicleYear(parseInt(year));
      loadDsiMakeListWithCustom(parseInt(year));
    } else {
      handleReset(Reset.Year, setFieldValue, setFieldTouched);
    }
  }

  function handleLoadModel(
    make: string | undefined,
    setFieldValue: (
      field: string,
      value: any,
      shouldValidate?: boolean | undefined
    ) => Promise<void | FormikErrors<VehicleFormValues>>,
    setFieldTouched: (
      field: string,
      isTouched?: boolean | undefined,
      shouldValidate?: boolean | undefined
    ) => Promise<void | FormikErrors<VehicleFormValues>>
  ) {
    if (selectedVehicle.year && make && make.length > 0) {
      setSelectedVehicleMake(make);
      loadDsiModelListWithCustom(selectedVehicle.year, make);
    } else {
      handleReset(Reset.Make, setFieldValue, setFieldTouched);
    }
  }

  function handleLoadEngine(
    modelId: string | undefined,
    model: string | undefined,
    setFieldValue: (
      field: string,
      value: any,
      shouldValidate?: boolean | undefined
    ) => Promise<void | FormikErrors<VehicleFormValues>>,
    setFieldTouched: (
      field: string,
      isTouched?: boolean | undefined,
      shouldValidate?: boolean | undefined
    ) => Promise<void | FormikErrors<VehicleFormValues>>
  ) {
    if (
      selectedVehicle.year &&
      selectedVehicle.makeName &&
      modelId &&
      modelId.length > 0
    ) {
      setSelectedVehicleModelId(modelId);

      let isCustom = selectedVehicle?.isCustom;

      if (selectedVehicle && !isCustom) {
        isCustom = model?.lastIndexOf("◊") ? model.lastIndexOf("◊") > 0 : false;
        setSelectedVehicleIsCustom(isCustom);
      }

      if (isCustom) setSelectedVehicleModel(modelId);
      else setSelectedVehicleModel(model);

      loadDsiEngineListWithCustom(
        selectedVehicle.year,
        selectedVehicle.makeName,
        modelId
      );
    } else {
      handleReset(Reset.Model, setFieldValue, setFieldTouched);
    }
  }

  function handleSelectEngine(
    engineId: any,
    engine: any,
    setFieldTouched: (
      field: string,
      isTouched?: boolean | undefined,
      shouldValidate?: boolean | undefined
    ) => Promise<void | FormikErrors<VehicleFormValues>>
  ) {
    if (engineId && engineId.length > 0) {
      setSelectedVehicleEngineId(engineId);
      setSelectedVehicleEngine(engine);
    }
  }

  function handleReset(
    type: Reset,
    setFieldValue: (
      field: string,
      value: any,
      shouldValidate?: boolean | undefined
    ) => Promise<void | FormikErrors<VehicleFormValues>>,
    setFieldTouched: (
      field: string,
      isTouched?: boolean | undefined,
      shouldValidate?: boolean | undefined
    ) => Promise<void | FormikErrors<VehicleFormValues>>
  ) {
    switch (type) {
      case Reset.Year: {
        setSelectedVehicleYear(undefined);
        resetMakeList();
      }
      case Reset.Make: {
        setSelectedVehicleMake(undefined);
        setFieldValue("makeName", undefined);
        setFieldTouched("makeName", false);
        resetModelList();
      }
      case Reset.Model: {
        setSelectedVehicleModelId(undefined);
        setFieldValue("modelId", undefined);
        setFieldValue("model", undefined);
        setFieldTouched("modelId", false);
        resetEngineList();
      }
      case Reset.Engine: {
        setSelectedVehicleEngineId(undefined);
        setFieldValue("engineId", undefined);
        setFieldValue("engine", undefined);
        setFieldTouched("engineId", false);
        break;
      }
    }
  }

  const validationSchema = Yup.object({
    year: Yup.string().required("Year is required"),
    makeName: Yup.string().required("Make is required"),
    modelId: Yup.string().required("Model is required"),
    engineId: Yup.string().required("Engine is required"),
  });

  return (
    <Grid stackable>
      <Grid.Column className="full-width" width="11">
        <PotentialCustomerMatchingMessage
          workflowStep={workflowStep}
        />
        <Formik
          validationSchema={validationSchema}
          enableReinitialize
          initialValues={selectedVehicle}
          onSubmit={(values) => handleFormSubmit(values)}
        >
          {({
            handleSubmit,
            isValid,
            isSubmitting,
            dirty,
            handleBlur,
            handleChange,
            errors,
            values,
            touched,
            setErrors,
            setFieldValue,
            setFieldTouched,
          }) => (
            <Form
              className="ui form error"
              autoComplete="off"
              onSubmit={handleSubmit}
            >
              <Grid stackable columns={3}>
                <Grid.Row columns={3}>
                  <Grid.Column width={3} verticalAlign="bottom">
                    <PageHeader
                      type="h1"
                      header={t("vehicle", { ns: "tickets" }).toString()}
                      className="modal-text-color"
                      textAlign="left"
                      divider={false}
                      removeDividerPadding={true}
                      addTitle={true}
                    />
                  </Grid.Column>
                  <Grid.Column width={6} verticalAlign="bottom">
                    <Button
                      floated="left"
                      type="button"
                      onClick={() =>
                        modalStore.openModal(
                          <QuickVinLookupForm
                            isActive={quickVinIsActive}
                          />
                        )
                      }
                    >
                      <Icon name="search" />
                      {t("look_up", { ns: "tickets" })}
                    </Button>
                  </Grid.Column>
                  <Grid.Column width={7} verticalAlign="bottom">
                    <Button
                      animated
                      floated="right"
                      color="green"
                      type="submit"
                      disabled={isSubmitting || !isValid}
                      loading={isSubmitting}
                    >
                      <Button.Content visible>
                        {t("continue", { ns: "tickets" })}
                      </Button.Content>
                      <Button.Content hidden>
                        <Icon name="caret right" />
                      </Button.Content>
                    </Button>
                    {selectedWorkflow.isCustomerInfoFirst && (
                      <Button
                        floated="right"
                        type="button"
                        onClick={() => {
                          navigate(`/ticket/start/customer/${id}`);
                        }}
                      >
                        <Icon name="caret left" />
                        {t("back", { ns: "tickets" })}
                      </Button>
                    )}
                  </Grid.Column>
                </Grid.Row>
                <Divider className="RemoveTopBottomPadding full-page"></Divider>
                <Grid.Row>
                  <Grid.Column className="style" width={3}>
                    <Header as="h3">{t("year", { ns: "tickets" })}</Header>
                    <MyDropdownInput
                      placeholder={t("year", { ns: "tickets" })}
                      label={undefined}
                      name="year"
                      options={yearList}
                      onChangeFunction={(d, f) => {
                        if (values.year && d !== values.year) {
                          handleReset(
                            Reset.Make,
                            setFieldValue,
                            setFieldTouched
                          );
                        }
                        handleLoadMake(d, setFieldValue, setFieldTouched);
                      }}
                      disabled={false}
                      className="myListbox520 width100 dropdown-custom"
                      upward={false}
                    />
                  </Grid.Column>
                  <Grid.Column width={6}>
                    <Header as="h3">{t("make", { ns: "tickets" })}</Header>
                    <MyDropdownInput
                      placeholder={t("card.make", { ns: "corrections" })}
                      label={undefined}
                      name="makeName"
                      options={makeList}
                      onChangeFunction={(d, f) => {
                        if (values.makeName && d !== values.makeName) {
                          handleReset(
                            Reset.Model,
                            setFieldValue,
                            setFieldTouched
                          );
                        }
                        handleLoadModel(d, setFieldValue, setFieldTouched);
                      }}
                      //disabled={!isYearSelected}
                      className="myListbox520 dropdown-custom"
                      upward={false}
                    />
                  </Grid.Column>
                  <Grid.Column width={7}>
                    <div className="style2">
                      <Header as="h3">{t("model", { ns: "tickets" })}</Header>
                      <MyDropdownInput
                        placeholder={t("card.model", { ns: "corrections" })}
                        label={undefined}
                        name="modelId"
                        options={modelList}
                        onChangeFunction={(d, f) => {
                          if (values.modelId && d !== values.modelId) {
                            handleReset(
                              Reset.Engine,
                              setFieldValue,
                              setFieldTouched
                            );
                          }

                          handleLoadEngine(
                            d,
                            f,
                            setFieldValue,
                            setFieldTouched
                          );
                        }}
                        //disabled={!isMakeSelected}
                        className="myListbox254 dropdown-custom"
                        upward={false}
                      />
                    </div>
                    <div className="style2">
                      <Header as="h3" className="MarginTop6px">
                        {t("engine", { ns: "tickets" })}
                      </Header>
                      <MyDropdownInput
                        placeholder={t("card.engine", {
                          ns: "corrections",
                        })}
                        label={undefined}
                        name="engineId"
                        options={engineList}
                        onChangeFunction={(d, f) => {
                          handleSelectEngine(d, f, setFieldTouched);
                        }}
                        className="myListbox254 dropdown-custom"
                        upward={false}
                      />
                    </div>
                  </Grid.Column>
                </Grid.Row>
                <Grid.Row columns={3}>
                  <Grid.Column width={5}>
                    <Button
                      className="save_button"
                      type="button"
                      content={t("custom", { ns: "tickets" })}
                      onClick={() => {
                        handleReset(Reset.Year, setFieldValue, setFieldTouched);
                        navigate(`/ticket/customVehicles/${id}/${complete}`);
                      }}
                    />
                  </Grid.Column>
                  <Grid.Column width={4}></Grid.Column>
                  <Grid.Column width={7}>
                    <Button
                      animated
                      floated="right"
                      color="green"
                      type="submit"
                      disabled={isSubmitting || !isValid}
                      loading={isSubmitting}
                    >
                      <Button.Content visible>
                        {t("continue", { ns: "tickets" })}
                      </Button.Content>
                      <Button.Content hidden>
                        <Icon name="caret right" />
                      </Button.Content>
                    </Button>
                  </Grid.Column>
                </Grid.Row>
              </Grid>
            </Form>
          )}
        </Formik>
      </Grid.Column>
      <Grid.Column width={5}>
        <Grid>
          <Grid.Row>
            <Grid.Column></Grid.Column>
          </Grid.Row>

          <Grid.Row>
            <Sidebar
              workflowStep={TicketWorkflowSteps.Vehicle}
              autoCapitalizeFirstLetter={autoCapitalizeFirstLetter}
            />
          </Grid.Row>
        </Grid>
      </Grid.Column>
    </Grid>
  );
});
