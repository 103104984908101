import { makeAutoObservable, runInAction } from "mobx";
import agent from "../../api/agent";
import { Pagination, PagingParams } from "../../models/pagination";
import {
  DeletedVehicles,
} from "../../models/systemAdmin";

export default class VehicleDataRecoveryStore {
  constructor() {
    makeAutoObservable(this);
  }

  loadingInitial = false;

  predicate = new Map().set("all", true);
  pagination: Pagination | null = null;
  pagingParams = new PagingParams(1, 25);

  resetAllPredicates = () => {
    this.predicate.clear();
  };

  setLoadingInitial = (state: boolean) => {
    this.loadingInitial = state;
  };

  resetPredicate = (keyToDelete: string) => {
    this.predicate.delete(keyToDelete);
  };

  setPredicate = (
    predicate: string,
    value:
      | string
      | number
      | boolean
      | Date
      | (string | number | boolean | Date)[]
      | undefined
  ) => {
    this.predicate.clear();
    if (value) this.predicate.set(predicate, value);
  };

  clearPredicate = () => {
    this.predicate.clear();
  };

  setPagination = (pagination: Pagination) => {
    this.pagination = pagination;
  };

  setPagingParams = (pagingParams: PagingParams) => {
    this.pagingParams = new PagingParams(pagingParams.pageNumber, 25);
  };

  get axiosParams() {
    const params = new URLSearchParams();
    params.append("pageNumber", this.pagingParams.pageNumber.toString());
    params.append("pageSize", this.pagingParams.pageSize.toString());
    this.predicate.forEach((value, key) => {
      if (key === "startDate" || key === "endDate") {
        params.append(key, (value as Date).toISOString());
      } else {
        params.append(key, value);
      }
    });
    return params;
  }
  DeletedVehiclesRegistry = new Map<string, DeletedVehicles>();

  restoreVehicle = async (id: string) => {
    try {
      this.DeletedVehiclesRegistry.clear();
      await agent.SystemAdminTools.restoreVehicle(id);
      runInAction(() => {
        this.DeletedVehiclesRegistry.delete(id);
      })
    } catch (error) {
      console.log(error);
    } finally {

    }
  };
  
  loadDeletedVehicles = async (id: string) => {
    try {
      const result = await agent.SystemAdminTools.getDeletedVehicles(
        this.axiosParams
      );
      runInAction(() => {
        result.data.forEach((x) => {
          this.setDeletedVehicles(x);
        });
        this.setPagination(result.pagination);
      });
    } catch (error) {
      console.log(error);
    }
  };

  private setDeletedVehicles = (deletedVehicles: DeletedVehicles) => {
    deletedVehicles.deletedDate = new Date(deletedVehicles.deletedDate);
    this.DeletedVehiclesRegistry.set(
      deletedVehicles.vehicleId,
      deletedVehicles
    );
  };

  get getDeletedVehicles() {
    return Array.from(this.DeletedVehiclesRegistry.values()).sort((a, b) => 
    b.deletedDate.getTime() - a.deletedDate.getTime()
    );
  }

}
